<ng-container
	*ngTemplateOutlet="(observableIsReadOnly.value$ | async) ? readonlyTemplate : editTemplate"></ng-container>

<ng-template #editTemplate>
	<ng-container *ngTemplateOutlet="(observableLayout.value$ | async) === 'inline' ? inline : normal"></ng-container>
</ng-template>

<ng-template #readonlyTemplate>
	<ion-label>{{ observableFieldValue.value$ | async | dateWithLocale: 'fr-FR' : 'EEE dd MMMM yyyy à HH:mm'
		}}</ion-label>
</ng-template>

<ng-template #normal>
	<div class="date-wrapper">
		<ng-container *ngTemplateOutlet="dateTimePicker"></ng-container>
	</div>
</ng-template>

<ng-template #inline>
	<ng-container *ngIf="observableLayoutParams.value$ | async as inlineParams">
		<calao-inline-field-layout [icon]="inlineParams.icon" [label]="inlineParams.label"
			[separator]="inlineParams.separator" [hideLabel]="observableHideLabel.value$ | async" [verticalCenter]="true">
			<calao-inline-field-layout-content>
				<div class="date-container" *ngIf="observableFieldValue.value$ | async as fieldValue">
					<span class="date-prefix" *ngIf="observableDatePrefix.value$ | async as datePrefix">
						{{ datePrefix }}
					</span>
					<div class="date-content">
						<div (click)="onDateClicked()" [ngClass]="(observableHideTimePicker.value$ | async) ? 'date-only' : ''"
							class="date-label">
							<ion-label class="date">
								{{ fieldValue | dateWithLocale: 'fr-FR' : (observableDateLabelFormat.value$ | async)}}
							</ion-label>
						</div>
						<div *ngIf="!(observableHideTimePicker.value$ | async)" (click)="onTimeClicked()">
							<ion-label class="time">
								{{ fieldValue | dateWithLocale: 'fr-FR' : 'HH:mm'}}</ion-label>
						</div>
					</div>
				</div>
			</calao-inline-field-layout-content>
			<div style="display: none;">
				<ng-container *ngTemplateOutlet="dateTimePicker"></ng-container>
			</div>
		</calao-inline-field-layout>
	</ng-container>
</ng-template>

<ng-template #dateTimePicker>
	<osapp-date-time #datePicker [params]="observableDatePickerParams.value$ | async"
		[model]="observableFieldValue.value$ | async" (modelChange)="onDateChanged($event)"
		class="picker date"></osapp-date-time>

	<osapp-date-time #timePicker *ngIf="!(observableHideTimePicker.value$ | async)"
		[params]="observableTimePickerParams.value$ | async" [model]="observableFieldValue.value$ | async"
		(modelChange)="onDateChanged($event)" class="picker time"></osapp-date-time>
</ng-template>