import { ChangeDetectionStrategy, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { map } from 'rxjs';
import { StringHelper } from '../../../../../../helpers/stringHelper';
import { ObserveProperty } from '../../../../../observable/decorators/observe-property.decorator';
import { ObservableProperty } from '../../../../../observable/models/observable-property';
import { FieldBase } from '../../../../models/FieldBase';
import { IInlineFieldLayoutParams } from '../inline-field-layout/models/iinline-field-layout-params';
import { IInlineField } from '../inline-field-layout/models/iinlineField';

@Component({
	templateUrl: './email-field.component.html',
	styleUrls: ['./inputs.component.scss', './email-field.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmailFieldComponent extends FieldBase<string> implements OnInit, IInlineField {

	//#region PROPERTIES

	@ViewChild("input") public input: ElementRef;

	/** Style de layout à afficher (undefined par défaut). */
	public layout: "inline";
	@ObserveProperty<EmailFieldComponent>({ sourcePropertyKey: "layout" })
	public readonly observableLayout = new ObservableProperty<"inline">();

	/** Paramètres d'affichage à passer au layout. */
	public layoutParams: IInlineFieldLayoutParams;
	@ObserveProperty<EmailFieldComponent>({ sourcePropertyKey: "layoutParams" })
	public readonly observableLayoutParams = new ObservableProperty<IInlineFieldLayoutParams>();

	/** Masque le libellé du champs dans le layout si `true` et que le champs est renseigné. */
	public hideLabelWhenFilled: boolean;
	@ObserveProperty<EmailFieldComponent>({ sourcePropertyKey: "hideLabelWhenFilled" })
	public readonly observableHideLabelWhenFilled = new ObservableProperty<boolean>();

	/** Masque le libellé du champs. */
	public readonly observableHideLabel = new ObservableProperty<boolean>().bind(
		this.observableFieldValue.value$.pipe(map((psMail: string) => !StringHelper.isBlank(psMail))),
		this
	);

	//#endregion PROPERTIES

	//#region METHODS

	public override ngOnInit(): void {
		const loParams: IInlineField = this.props as IInlineField;
		this.observableLayout.value = loParams.layout;
		this.observableLayoutParams.value = loParams.layoutParams;
		this.observableHideLabelWhenFilled.value = loParams.hideLabelWhenFilled;
	}

	//#endregion
}