import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalOptions } from '@ionic/core';
import { EMPTY, Observable, combineLatest, defer, of, throwError } from 'rxjs';
import { map, mapTo, mergeMap, take, tap } from 'rxjs/operators';
import { ComponentBase } from '../../../../helpers/ComponentBase';
import { ArrayHelper } from '../../../../helpers/arrayHelper';
import { GuidHelper } from '../../../../helpers/guidHelper';
import { PathHelper } from '../../../../helpers/path-helper';
import { StoreHelper } from '../../../../helpers/storeHelper';
import { EntityLinkService } from '../../../../services/entityLink.service';
import { GalleryService } from '../../../../services/gallery.service';
import { EntityModalComponent } from '../../../entities/components/entity-modal/entity-modal.component';
import { Entity } from '../../../entities/models/entity';
import { IEntityModalParams } from '../../../entities/models/ientity-modal-params';
import { EntitiesService } from '../../../entities/services/entities.service';
import { ModalService } from '../../../modal/services/modal.service';
import { ObserveProperty } from '../../../observable/decorators/observe-property.decorator';
import { ObservableProperty } from '../../../observable/models/observable-property';
import { ESelectorDisplayMode } from '../../../selector/selector/ESelectorDisplayMode';
import { ISelectOption } from '../../../selector/selector/ISelectOption';
import { Queue } from '../../../utils/queue/decorators/queue.decorator';
import { secure } from '../../../utils/rxjs/operators/secure';
import { DocExplorerConfig } from '../../models/doc-explorer-config';
import { Document } from '../../models/document';
import { EAddDocumentModaleStep } from '../../models/eadd-document-modale-step';
import { EExplorerDisplayMode } from '../../models/eexplorer-display-mode';
import { EExplorerMode } from '../../models/eexplorer-mode';
import { EListItemOption } from '../../models/elist-item-option';
import { Folder } from '../../models/folder';
import { FolderConfig } from '../../models/folder-config';
import { FolderContent } from '../../models/folder-content';
import { FormDocument } from '../../models/form-document';
import { IAddDocumentParams } from '../../models/iadd-document-modal-params';
import { IListItemOptionClickEvent } from '../../models/ilist-item-option-click-event';
import { DocExplorerDocumentsService } from '../../services/doc-explorer-documents.service';
import { DocExplorerService } from '../../services/doc-explorer.service';
import { DocumentStatusService } from '../../services/document-status.service';
import { AddDocumentModalComponent } from '../add-document-modal/add-document-modal.component';
import { FolderListComponent } from '../folder-list/folder-list.component';

@Component({
	selector: 'calao-doc-explorer',
	templateUrl: './doc-explorer.component.html',
	styleUrls: ['./doc-explorer.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocExplorerComponent extends ComponentBase implements OnDestroy {

	//#region FIELDS

	/** Événement lors du changement de chemin. */
	@Output("onPathChanged") private readonly moPathChangedEvent = new EventEmitter<string>();
	@Output("onDisplayModeChanged") private readonly moDisplayModeChangedEvent = new EventEmitter<EExplorerDisplayMode>();
	@Output("onDocumentPicked") private readonly moDocumentPickedEvent = new EventEmitter<Document>();

	/** Dossier sélectionné. */
	private readonly moObservableSelectedFolder = new ObservableProperty<Folder>();

	//#endregion FIELDS

	//#region PROPERTIES

	/** Chemin du dossier parent sur lequel limiter la récupération des documents. */
	@Input() public rootPath?: string | null;
	@ObserveProperty<DocExplorerComponent>({ sourcePropertyKey: "rootPath" })
	public readonly observableRootPath = new ObservableProperty<string | undefined>(undefined);

	/** Mode. */
	@Input() public mode: EExplorerMode;
	@ObserveProperty<DocExplorerComponent>({ sourcePropertyKey: "mode" })
	public readonly observableMode = new ObservableProperty<EExplorerMode>(EExplorerMode.explorer);

	/** Mode d'affichage. */
	@Input() public displayMode: EExplorerDisplayMode;
	@ObserveProperty<DocExplorerComponent>({ sourcePropertyKey: "displayMode" })
	public readonly observableDisplayMode = new ObservableProperty<EExplorerDisplayMode>();

	/** Route courante. */
	@Input() public currentPath?: string | null;
	@ObserveProperty<FolderListComponent>({ sourcePropertyKey: "currentPath" })
	public readonly observableCurrentPath = new ObservableProperty<string | undefined>();

	/** Flux indiquant si on peut ajouter un document. */
	public readonly observableCanCreate = new ObservableProperty<boolean>(false);
	/** Flux indiquant si on affiche le chemin de navigation. */
	public readonly observableDisplayNavigationTree = new ObservableProperty<boolean>(false);

	/** Enum mode de sélection. */
	public readonly selectorDisplayMode = ESelectorDisplayMode;
	/** Enum mode d'affichage. */
	public readonly explorerDisplayMode = EExplorerDisplayMode;
	/** Liste des options d'affichage. */
	public readonly displayModeOptions: ReadonlyArray<ISelectOption<EExplorerDisplayMode>> = [
		{ label: "Dossiers", value: EExplorerDisplayMode.folders },
		{ label: "Date", value: EExplorerDisplayMode.date }
	];

	//#endregion

	//#region METHODS

	constructor(
		protected readonly isvcDocExplorer: DocExplorerService,
		protected readonly isvcDocExplorerDocuments: DocExplorerDocumentsService,
		protected readonly isvcDocumentStatus: DocumentStatusService,
		protected readonly isvcModal: ModalService,
		protected readonly isvcEntityLink: EntityLinkService,
		protected readonly isvcEntities: EntitiesService,
		private readonly isvcGallery: GalleryService,
		private readonly ioRoute: ActivatedRoute,
		poChangeDetector: ChangeDetectorRef
	) {
		super(poChangeDetector);

		this.observableCurrentPath.value$.pipe(
			map((psPath: string) => psPath ? this.isvcDocExplorerDocuments.checkFolderPermissions(psPath, "create", false) : false),
			tap((pbCanCreate: boolean) => this.observableCanCreate.value = pbCanCreate),
			secure(this)
		).subscribe();

		this.ioRoute.queryParams.pipe(
			tap((poQueryParams: { displayMode?: EExplorerDisplayMode }) => {
				this.observableDisplayMode.value = poQueryParams.displayMode ?? EExplorerDisplayMode.folders;
			}),
			secure(this)
		).subscribe();
	}

	public onAddDocumentClickedAsync(): Promise<boolean> {
		return combineLatest([
			this.observableCurrentPath.value$,
			this.getEntity$(),
			this.isvcDocExplorerDocuments.getConfig$()
		]).pipe(
			mergeMap(([psPath, poEntity, poConfig]: [string, Entity | undefined, DocExplorerConfig | undefined]) => {
				return this.getSelectedFolder$(psPath).pipe(
					tap((poFolder: Folder) => this.moObservableSelectedFolder.value = poFolder),
					mapTo([psPath, poEntity, poConfig])
				);
			}),
			take(1),
			mergeMap(([psPath, poEntity, poConfig]: [string, Entity | undefined, DocExplorerConfig | undefined]) => {

				const loFolderConfig: FolderConfig | undefined = this.isvcDocExplorerDocuments.getMatchingPathFolderConfig(psPath, poConfig);

				if (!loFolderConfig?.documentTypes || (loFolderConfig.documentTypes.files && loFolderConfig.documentTypes.forms) || (loFolderConfig.documentTypes.files && !loFolderConfig.documentTypes.forms)) {

					const loParams: IAddDocumentParams = {
						path: psPath,
						parentEntity: poEntity,
						activatedRoute: this.ioRoute,
						documentTypes: loFolderConfig?.documentTypes,
						step: loFolderConfig?.documentTypes ? EAddDocumentModaleStep.typeSelection : EAddDocumentModaleStep.entitySelection
					};

					return this.isvcModal.open<Document | undefined>({
						component: AddDocumentModalComponent,
						componentProps: loParams
					});
				}
				else {
					if (this.moObservableSelectedFolder.value) {
						if (ArrayHelper.hasElements(loFolderConfig?.documentTypes.forms) && !loFolderConfig?.documentTypes.files)
							return this.isvcDocExplorerDocuments.addFromForm$(this.moObservableSelectedFolder.value, this.ioRoute);
					}
					return EMPTY;
				}
			}),
			map((poDocument: Document | undefined) => {
				if (poDocument) {
					if (this.observableMode.value === EExplorerMode.picker)
						this.moDocumentPickedEvent.emit(poDocument);
				}
				return !!poDocument;
			}),
		).toPromise();
	}

	private getSelectedFolder$(psPath: string): Observable<Folder> {
		return this.isvcDocExplorerDocuments.getFolderContent$(PathHelper.parsePath(psPath)).pipe(
			map((poFolderContent: FolderContent) => poFolderContent.current)
		);
	}

	private getEntity$(): Observable<Entity | undefined> {
		return this.isvcEntityLink.currentEntity ?
			this.isvcDocExplorerDocuments.getEntity$(
				this.isvcEntityLink.currentEntity._id,
				StoreHelper.getDatabaseIdFromCacheData(this.isvcEntityLink.currentEntity, undefined, false)
			).pipe(map((poEntity?: Entity) => poEntity ?? this.isvcEntityLink.currentEntity)) :
			of(undefined);
	}

	public onPathChanged(psPath: string): void {
		this.moPathChangedEvent.emit(psPath);
	}

	public async openDocumentAsync(poDocument: Document): Promise<void> {
		await this.openDocument$(poDocument).pipe(secure(this)).toPromise();
	}

	private openDocument$(poDocument: Document): Observable<boolean> {
		return defer(() => {
			if (poDocument instanceof FormDocument) {
				if (this.observableMode.value === EExplorerMode.picker)
					return this.openDocumentModalAsync(poDocument);
				return this.isvcDocExplorerDocuments.getFolderContent$(ArrayHelper.getFirstElement(poDocument.paths)).pipe(
					take(1),
					mergeMap((poFolder: FolderContent) => {
						return this.isvcEntities.navigateToEntityViewAsync(
							poDocument,
							this.ioRoute
						);
					})
				);
			}
			else
				return this.isvcGallery.openFile(poDocument);
		}).pipe(
			mergeMap(() => this.markAsRead$(poDocument))
		);
	}

	public async openDocumentModalAsync(poDocument: Document): Promise<void> {
		const loModalParams: IEntityModalParams = {
			entityDescGuid: this.isvcEntities.getEntityDescGuidFromEntityDescId(this.isvcEntities.getEntityDescriptor(poDocument)?._id),
			entityGuid: GuidHelper.extractGuid(poDocument._id)
		};

		const loModalOptions: ModalOptions = {
			component: EntityModalComponent,
			componentProps: loModalParams
		};

		return this.isvcModal.open<void>(loModalOptions).toPromise();
	}

	private editDocument$(poDocument: FormDocument): Observable<boolean> {
		return defer(() => this.isvcEntities.navigateToEntityEditAsync(
			poDocument,
			this.ioRoute
		));
	}

	public onDisplayModeChanged(peSelectedDisplayMode: EExplorerDisplayMode): void {
		this.moDisplayModeChangedEvent.emit(this.observableDisplayMode.value = peSelectedDisplayMode);
	}

	private moveToTrash$(poDocument: Document): Observable<boolean> {
		return this.isvcDocExplorer.moveToTrash$(poDocument, ArrayHelper.getFirstElement(poDocument.paths));
	}

	private restore$(poDocument: Document): Observable<boolean> {
		return this.isvcDocExplorer.restore$(poDocument, ArrayHelper.getFirstElement(poDocument.paths));
	}

	private delete$(poDocument: Document): Observable<boolean> {
		return this.isvcDocExplorer.delete$(poDocument, ArrayHelper.getFirstElement(poDocument.paths));
	}

	/** Marque un document comme lu.
	 * @param poDocument
	 */
	public markAsRead$(poDocument: Document): Observable<boolean> {
		return this.isvcDocumentStatus.changeReadSatus$(poDocument, "read");
	}

	/** Marque un document comme non lu.
	 * @param poDocument
	 */
	public markAsUnread$(poDocument: Document): Observable<boolean> {
		return this.isvcDocumentStatus.changeReadSatus$(poDocument, "notRead");
	}

	public async onOptionClickedAsync(poEvent: IListItemOptionClickEvent): Promise<boolean> {
		return this.onOptionClicked$(poEvent).toPromise();
	}

	public openAsync(poDocument: Document): Promise<boolean> {
		return this.onOptionClicked$({ key: EListItemOption.read, document: poDocument }).toPromise();
	}

	@Queue<
		DocExplorerComponent,
		Parameters<DocExplorerComponent["onOptionClicked$"]>,
		ReturnType<DocExplorerComponent["onOptionClicked$"]>
	>({
		excludePendings: true
	})
	private onOptionClicked$(poEvent: IListItemOptionClickEvent): Observable<boolean> {
		switch (poEvent.key) {
			case EListItemOption.delete:
				return this.delete$(poEvent.document);
			case EListItemOption.read:
				return this.openDocument$(poEvent.document);
			case EListItemOption.restore:
				return this.restore$(poEvent.document);
			case EListItemOption.trash:
				return this.moveToTrash$(poEvent.document);
			case EListItemOption.markAsRead:
				return this.markAsRead$(poEvent.document);
			case EListItemOption.markAsUnread:
				return this.markAsUnread$(poEvent.document);
			case EListItemOption.share:
				return this.share$(poEvent.document);
			case EListItemOption.pick:
				return this.pick$(poEvent.document);
			default:
				if (poEvent.key === EListItemOption.edit && poEvent.document instanceof FormDocument)
					return this.editDocument$(poEvent.document);
				return throwError(() => new Error(`Event type ${poEvent.key} not supported for document ${poEvent.document._id}.`));
		}
	}

	private share$(poDocument: Document): Observable<boolean> {
		return defer(() => this.isvcDocExplorerDocuments.shareAsync(poDocument));
	}

	private pick$(poDocument: Document): Observable<boolean> {
		this.moDocumentPickedEvent.next(poDocument);
		return of(true);
	}

	//#endregion METHODS

}
