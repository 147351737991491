import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ModalOptions } from '@ionic/core';
import { filter, Observable, tap } from 'rxjs';
import { ArrayHelper } from '../../../../../../helpers/arrayHelper';
import { IGroup } from '../../../../../../model/contacts/IGroup';
import { GroupsService } from '../../../../../../services/groups.service';
import { ModalService } from '../../../../../modal/services/modal.service';
import { ObservableArray } from '../../../../../observable/models/observable-array';
import { ObservableProperty } from '../../../../../observable/models/observable-property';
import { IDeletableTag } from '../../../../../tags/models/ideletable-tag';
import { Queue } from '../../../../../utils/queue/decorators/queue.decorator';
import { secure } from '../../../../../utils/rxjs/operators/secure';
import { FieldBase } from '../../../../models/FieldBase';
import { FormsService } from '../../../../services/forms.service';
import { IInlineListField } from '../inline-list-field-layout/models/iinline-list-field';
import { IInlineListFieldLayoutParams } from '../inline-list-field-layout/models/iinline-list-field-layout-params';
import { GroupsFieldModalComponent } from './groups-field-modal/groups-field-modal.component';
import { IGroupsFieldModal } from './models/igroups-field-modal';

@Component({
	selector: 'calao-groups-field',
	templateUrl: './groups-field.component.html',
	styleUrls: ['./groups-field.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class GroupsFieldComponent extends FieldBase<IGroup[]> implements OnInit, IInlineListField {

	//#region FIELDS

	/** Tableau des groupes sélectionnables. */
	public readonly moObservableDisplayableGroups = new ObservableArray<IGroup>();

	//#endregion FIELDS

	//#region PROPERTIES

	public layout: 'inline';
	public layoutParams: IInlineListFieldLayoutParams;

	/** Paramètres du champs. */
	public readonly observableParams = new ObservableProperty<IInlineListField>();
	public readonly observableGroupTags = new ObservableArray<IDeletableTag>();

	//#endregion PROPERTIES

	//#region METHODS

	constructor(
		private readonly isvcModal: ModalService,
		private readonly isvcGroups: GroupsService,
		poForms: FormsService
	) {
		super(poForms);
	}

	public override ngOnInit() {
		this.observableParams.value = this.props.data as IInlineListField;

		this.observableFieldValue.value = ArrayHelper.hasElements(this.model[this.fieldKey]) ? this.model[this.fieldKey] : [];

		this.observableFieldValue.value$.pipe(
			tap((paGroups: IGroup[]) => this.observableGroupTags.resetArray(this.getTagsFromGroups(paGroups))),
			secure(this)
		).subscribe();

		this.isvcGroups.getDisplayableGroups(true).pipe(
			tap((paGroups: IGroup[]) => this.moObservableDisplayableGroups.resetArray(paGroups)),
			secure(this)
		).subscribe();
	}

	private getTagsFromGroups(paGroups: IGroup[]): IDeletableTag[] {
		return paGroups.map((poGroup: IGroup) => {
			return {
				id: poGroup._id,
				label: poGroup.name,
				deletable: true
			}
		})
	}

	public onRemoveTagClicked(poTag: IDeletableTag): void {
		const laGroups: IGroup[] = [...this.observableFieldValue.value];
		const loDeletedGroup: IGroup = ArrayHelper.removeElementById(laGroups, poTag.id);
		if (loDeletedGroup)
			this.observableFieldValue.value = [...laGroups];
	}

	public onAddClicked(): void {
		this.showModal$().subscribe();
	}

	@Queue<GroupsFieldComponent, Parameters<GroupsFieldComponent["showModal$"]>, ReturnType<GroupsFieldComponent["showModal$"]>>({
		excludePendings: true
	})
	private showModal$(): Observable<IGroupsFieldModal> {
		const loModalOptions: ModalOptions = {
			component: GroupsFieldModalComponent,
			componentProps: {
				groups: this.moObservableDisplayableGroups,
			} as IGroupsFieldModal,
			canDismiss: true
		}

		return this.isvcModal.open<IGroupsFieldModal>(loModalOptions).pipe(
			filter((poGroups: IGroupsFieldModal) => !!poGroups),
			tap((poGroups: IGroupsFieldModal) => this.observableFieldValue.value = [...this.observableFieldValue.value, ...poGroups.groups])
		);
	}

	//#endregion METHODS

}
