<ng-container
	*ngTemplateOutlet="(observableIsReadOnly.value$ | async) ? readonlyTemplate : editTemplate"></ng-container>

<ng-template #editTemplate>
	<ng-container *ngIf="observableLayoutParams.value$ | async as inlineParams">
		<calao-inline-field-layout [icon]="inlineParams.icon" [label]="inlineParams.label"
			[separator]="inlineParams.separator" [hideLabel]="observableHideLabel.value$ | async" [verticalCenter]="true"
			(onFieldClicked)="onFieldClicked()">
			<calao-inline-field-layout-content>
				<div class="date-container" *ngIf="observableFieldValue.value$ | async as fieldValue">
					<div class="date-label">
						<ion-label class="date">
							{{ fieldValue | dateWithLocale: 'fr-FR' : (observableDateLabelFormat.value$ | async)}}
						</ion-label>
					</div>
				</div>
			</calao-inline-field-layout-content>
			<div style="display: none;">
				<ng-container *ngTemplateOutlet="dateTimePicker"></ng-container>
			</div>
		</calao-inline-field-layout>
	</ng-container>
</ng-template>

<ng-template #readonlyTemplate>
	<ion-label>
		{{ observableFieldValue.value$ | async | dateWithLocale: 'fr-FR' : (observableDateLabelFormat.value$ | async) }}
	</ion-label>
</ng-template>

<ng-template #dateTimePicker>
	<osapp-date-time #datePicker [params]="observableDatePickerParams.value$ | async"
		[model]="observableFieldValue.value$ | async" (modelChange)="onDateChanged($event)"
		class="picker date"></osapp-date-time>
</ng-template>