import { IIndexedArray } from '../model/IIndexedArray';
import { ArrayHelper } from './arrayHelper';
import { ObjectHelper } from './objectHelper';
/** Permet de mettre à disposition des méthodes pour aider à manipuler une Map. */
export abstract class MapHelper {

	//#region METHODS

	/** Transforme les valeurs d'une Map en Array.
	 * @param poMap Map à transformer.
	 */
	public static valuesToArray<K, V>(poMap?: Map<K, V> | ReadonlyMap<K, V>): V[] {
		return Array.from(poMap?.values() ?? []);
	}

	/** Transforme les clés d'une Map en Array.
	 * @param poMap Map à transformer.
	 */
	public static keysToArray<K, V>(poMap: Map<K, V> | ReadonlyMap<K, V>): K[] {
		return Array.from(poMap.keys());
	}

	/** Ajoute un élément ou ensemble d'éléments dans une map en fonction d'une clé associée ; crée une nouvelle paire clé/valeurs si elle n'existe pas.
	 * @param poMap Map dans laquelle ajouter la/les nouvelle(s) valeur(s).
	 * @param poKey Clé de la paire où ajouter la/les nouvelle(s) valeur(s).
	 * @param poData Donnée à ajouter dans une paire de la map.
	 */
	public static addValues<K, V>(poMap: Map<K, V[]>, poKey: K, poData: V | V[]): void {
		const laNewValues: V[] = poData instanceof Array ? poData : [poData];

		if (poMap.has(poKey)) // Si la map a déjà une paire pour cette clé, on ajoute à l'existant.
			poMap.get(poKey)?.push(...laNewValues);
		else // Sinon, on crée une nouvelle paire.
			poMap.set(poKey, laNewValues);
	}

	/** Transforme une map en objet indexé.
	 * @param poMap
	 */
	public static mapToObject<K, V>(poMap: Map<K, V> | ReadonlyMap<K, V>): IIndexedArray<V> {
		const loObject: IIndexedArray<V> = {};

		poMap.forEach((paValues: V, poKey: K) => loObject[poKey as unknown as string] = paValues);

		return loObject;
	}

	/** Permet de transformer la valeur d'une map.
	 * @param poMap Map à transformer.
	 * @param pfMapFunc Fonction de transformation de la valeur de la map.
	 * @returns
	 */
	public static map<K, V, T>(poMap: Map<K, V> | ReadonlyMap<K, V>, pfMapFunc: (poValue: V, poKey: K) => T): Map<K, T> {
		const loMap = new Map<K, T>();

		poMap.forEach((poValue: V, poKey: K) => loMap.set(poKey, pfMapFunc(poValue, poKey)));

		return loMap;
	}

	/** Retourne l'addition de la longueur de chacune des liste de la map.
	 * @param poMap
	 */
	public static totalLenght<K, V extends Array<any>>(poMap: Map<K, V> | ReadonlyMap<K, V>): number {
		let lnTotalLenght = 0;

		poMap.forEach((poValue: V) => {
			lnTotalLenght += poValue.length;
		});

		return lnTotalLenght;
	}

	/** Crée une nouvelle map en mettant en clé une valeur donnée par une fonction (en se basant sur la valeur d'une entrée de l'ancienne map) et en valeur la clé associée à l'entrée dans l'ancienne map.
	 * @param poMap
	 */
	public static reverseWithKeySelector<K, V, T = V>(poMap: Map<K, V> | ReadonlyMap<K, V>, pfKeySelector?: (poItem: V) => T): Map<T, K[]> {
		const loNewMap = new Map<T, K[]>();

		poMap.forEach((poValue: V, poKey: K) => {
			const loKey: T = pfKeySelector ? pfKeySelector(poValue) : poValue as any;
			loNewMap.has(loKey) ? loNewMap.get(loKey)?.push(poKey) : loNewMap.set(loKey, [poKey]);
		});

		return loNewMap;
	}

	/** Stringifie une map avec ses entrées.
	 * @param poMap Map qu'on veut stringifier.
	 */
	public static toString<K, V>(poMap: Map<K, V> | ReadonlyMap<K, V>): string {
		return JSON.stringify(this.mapToObject(poMap));
	}

	public static merge<K, V>(paMaps: Map<K, V>[], pfMerger: (poAcc: V, poValue: V) => V): Map<K, V> {
		const loMap = new Map<K, V>();

		paMaps.forEach((poMap: Map<K, V>) => {
			poMap.forEach((poValue: V, poKey: K) => {
				const loMergedValue: V = loMap.get(poKey);

				loMap.set(poKey, ObjectHelper.isDefined(loMergedValue) ? pfMerger(loMergedValue, poValue) : poValue);
			});
		});

		return loMap;
	}

	/** Transforme un tableau en map avec en clé : la valeur du nom du champs défini comme clé ; en valeur : un tableau des éléments ayant la même clé.
	 * @param paArray Tableau des données à transformer en map.
	 * @param psFieldName Nom d'un champs d'un élément du tableau qui deviendra la clé d'une entrée de la map.
	 */
	public static arrayToMap<T>(paArray: T[], psFieldName: keyof T): Map<T[keyof T], T[]> {
		const loMap = new Map<T[keyof T], T[]>();

		if (ArrayHelper.hasElements(paArray)) {
			paArray.forEach((poValue: T) =>
				loMap.has(poValue[psFieldName]) ? loMap.get(poValue[psFieldName]).push(poValue) : loMap.set(poValue[psFieldName], [poValue])
			);
		}

		return loMap;
	}

	//#endregion

}