import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertButton } from '@ionic/core';
import { Observable, combineLatest, defer, firstValueFrom, of, throwError } from 'rxjs';
import { catchError, concatMap, finalize, map, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import { ArrayHelper } from '../../../helpers/arrayHelper';
import { GuidHelper } from '../../../helpers/guidHelper';
import { IdHelper } from '../../../helpers/idHelper';
import { MapHelper } from '../../../helpers/mapHelper';
import { ObjectHelper } from '../../../helpers/objectHelper';
import { StringHelper } from '../../../helpers/stringHelper';
import { ConfigData } from '../../../model/config/ConfigData';
import { EPrefix } from '../../../model/EPrefix';
import { IFlag } from '../../../model/flag/IFlag';
import { IIndexedObject } from '../../../model/IIndexedObject';
import { ActivePageManager } from '../../../model/navigation/ActivePageManager';
import { EAvatarSize } from '../../../model/picture/EAvatarSize';
import { IAvatar } from '../../../model/picture/IAvatar';
import { ERouteUrlPart } from '../../../model/route/ERouteUrlPart';
import { EDatabaseRole } from '../../../model/store/EDatabaseRole';
import { IDataSource } from '../../../model/store/IDataSource';
import { IStoreDocument } from '../../../model/store/IStoreDocument';
import { IUiResponse } from '../../../model/uiMessage/IUiResponse';
import { EntityLinkService } from '../../../services/entityLink.service';
import { FlagService } from '../../../services/flag.service';
import { ShowMessageParamsPopup } from '../../../services/interfaces/ShowMessageParamsPopup';
import { LoadingService } from '../../../services/loading.service';
import { PatternResolverService } from '../../../services/pattern-resolver.service';
import { Store } from '../../../services/store.service';
import { UiMessageService } from '../../../services/uiMessage.service';
import { IFormDefinition } from '../../forms/models/IFormDefinition';
import { IListDefinitionsField } from '../../forms/models/IListDefinitionsField';
import { Loader } from '../../loading/Loader';
import { EPermissionScopes } from '../../permissions/models/epermission-scopes';
import { EPermissionsFlag } from '../../permissions/models/EPermissionsFlag';
import { TCRUDPermissions } from '../../permissions/models/tcrud-permissions';
import { PermissionsService } from '../../permissions/services/permissions.service';
import { DestroyableServiceBase } from '../../services/models/destroyable-service-base';
import { IDataSourceRemoteChanges } from '../../store/model/IDataSourceRemoteChanges';
import { ModelResolver } from '../../utils/models/model-resolver';
import { IGetVersionedDocumentsParams } from '../../versioned-documents/models/iget-versioned-documents-params';
import { VersionedDocumentsService } from '../../versioned-documents/services/versioned-documents.service';
import { EEntityFlag } from '../models/eentity-flag';
import { EEntityLinkType } from '../models/eentity-link-type';
import { Entity } from '../models/entity';
import { EntityLink } from '../models/entity-link';
import { IEntity } from '../models/ientity';
import { IEntityCategory } from '../models/ientity-category';
import { IEntityDescriptor } from '../models/ientity-descriptor';
import { IEntityDescriptorContext } from '../models/ientity-descriptor-context';
import { IEntityDescriptorContextLink } from '../models/ientity-descriptor-context-link';
import { IEntityDescriptorEntityParams } from '../models/ientity-descriptor-entity-params';
import { IEntityEntriesListDefinition } from '../models/ientity-entries-list-definition';
import { IEntityEntriesListParamsCreateParams } from '../models/ientity-entries-list-params-create-params';
import { IEntityEntriesListParamsFilters } from '../models/ientity-entries-list-params-filters';
import { StateMachine } from '../models/state-machine';

@Injectable({
	providedIn: "root"
})
export class EntitiesService extends DestroyableServiceBase {

	//#region FIELDS

	private static readonly C_LOG_ID = "ENTITIES.S::";
	/** URL de base pour accéder aux descripteurs de formulaires locaux.  */
	private static readonly C_LOCAL_DESCRIPTORS_BASE_URL = "/entities/descriptors/";
	/** Extension des descripteurs de formulaires locaux.  */
	private static readonly C_LOCAL_DESCRIPTORS_EXTENSION = ".entityDesc.json";
	private static C_ENTITY_DESCRIPTORS_BY_GUID_CACHE = new Map<string, IEntityDescriptor>();

	private readonly moActivePageManager = new ActivePageManager(this, this.ioRouter, () => true); // On force l'activité en permanence

	//#endregion

	//#region METHODS

	constructor(
		private readonly isvcStore: Store,
		private readonly isvcPatternResolver: PatternResolverService,
		private readonly isvcPermissions: PermissionsService,
		private readonly ioRouter: Router,
		private readonly isvcFlags: FlagService,
		private readonly isvcLoading: LoadingService,
		private readonly isvcVersionedDocuments: VersionedDocumentsService,
		private readonly isvcUiMessage: UiMessageService,
		private readonly isvcEntityLink: EntityLinkService
	) {
		super();

		this.isvcFlags.observeFlag(EPermissionsFlag.isLoaded).pipe(
			concatMap(async (poFlag: IFlag) => {
				if (!poFlag.value) {
					EntitiesService.C_ENTITY_DESCRIPTORS_BY_GUID_CACHE.clear();
					this.isvcFlags.setFlagValue(EEntityFlag.entitiesReady, false);
				}
				else
					await this.initAsync();
			})
		).subscribe();
	}

	private async initAsync(): Promise<void> {
		// On ne fait l'init que si elle n'a pas déjà été faite.
		if (!this.isvcFlags.getFlagValue(EEntityFlag.entitiesReady)) {
			await this.isvcFlags.waitForFlagAsync(EPermissionsFlag.isLoaded, true);

			const loLoader: Loader = await this.isvcLoading.create("Chargement des entités");
			await loLoader.present();

			const loGetVersionedDocumentsParams: IGetVersionedDocumentsParams = {
				prefix: EPrefix.entityDesc,
				suffix: EntitiesService.C_LOCAL_DESCRIPTORS_EXTENSION,
				ids: ConfigData.builtInEntityDescIds,
				baseUrl: EntitiesService.C_LOCAL_DESCRIPTORS_BASE_URL,
				roles: [EDatabaseRole.formsDefinitions],
				activePageManager: this.moActivePageManager
			};

			this.isvcVersionedDocuments.getVersionedDocumentsByGuid$<IEntityDescriptor>(loGetVersionedDocumentsParams).pipe(
				tap((poResult: Map<string, IEntityDescriptor>) => {
					EntitiesService.C_ENTITY_DESCRIPTORS_BY_GUID_CACHE = poResult;
					loLoader.dismiss();
					this.isvcFlags.setFlagValue(EEntityFlag.entitiesReady, true);
				}),
				finalize(() => loLoader.dismiss())
			).subscribe();

			await this.isvcFlags.waitForFlagAsync(EEntityFlag.entitiesReady, true);
		}
	}

	public getEntityDescGuidFromEntityDescId(psEntityDescId?: string): string {
		return psEntityDescId?.split("_")[1] ?? "";
	}

	/** Récupère la description dont l'id est en paramètre sur la base de données associée aux descriptions d'entités (descriptionsDb).
	 * @param psEntityDescGuidOrId id du descripteur d'entité.
	 */
	public getDescriptor$(
		psEntityDescGuidOrId?: string,
		poEntityParams?: IEntityDescriptorEntityParams,
		poActivePageManager?: ActivePageManager,
		poContext?: IEntityDescriptorContext,
	): Observable<IEntityDescriptor | undefined> {
		return defer(() => {
			if (StringHelper.isBlank(psEntityDescGuidOrId))
				return of(undefined);

			const loDescriptor: IEntityDescriptor | undefined = this.getDescriptor(
				IdHelper.getGuidFromId(psEntityDescGuidOrId, EPrefix.entityDesc)
			);

			if (loDescriptor) {
				console.debug(`${EntitiesService.C_LOG_ID}Form descriptor ${loDescriptor._id} is selected for application version ${ConfigData.appInfo.appVersion}.`);
				return of(ObjectHelper.clone(loDescriptor)); // On clone pour ne pas polluer le cache.
			}
			else
				return throwError(() => new Error(`Aucun formulaire trouvé pour le type "${psEntityDescGuidOrId}"`));
		}).pipe(
			switchMap((poDesc?: IEntityDescriptor) =>
				this.hydrateEntityDescriptor(poDesc, poContext, poEntityParams, poActivePageManager)
			),
			catchError(poError => {
				console.error(`${EntitiesService.C_LOG_ID}Erreur récupération des données :`, poError);
				return throwError(() => poError);
			})
		);
	}

	/** Récupère la description dont l'id est en paramètre.
	 * @param psEntityDescUid id du descripteur d'entité.
	 */
	public getDescriptor(psEntityDescUid: string,): IEntityDescriptor | undefined {
		return EntitiesService.C_ENTITY_DESCRIPTORS_BY_GUID_CACHE.get(psEntityDescUid);
	}

	private hydrateEntityDescriptor(
		poDescriptor?: IEntityDescriptor,
		poContext?: IEntityDescriptorContext,
		poEntityParams?: IEntityDescriptorEntityParams,
		poActivePageManager?: ActivePageManager
	): Observable<IEntityDescriptor | undefined> {
		if (!poDescriptor)
			return of(poDescriptor);

		const lsEntityId: string = this.getEntityId(poDescriptor, poEntityParams?.guid);

		return this.getDescriptorModel$(lsEntityId, poEntityParams, poActivePageManager).pipe(
			mergeMap((poEntity?: Entity) => {
				const loDesc: IEntityDescriptor = ObjectHelper.clone(poDescriptor);

				return this.getParentEntry$(poDescriptor, poEntity, poContext, poActivePageManager).pipe(
					map((poParentEntity?: Entity) => {
						const loContext: IEntityDescriptorContext = this.createDescriptorContext(
							poContext,
							loDesc,
							poEntityParams?.guid,
							poDescriptor,
							poParentEntity
						);

						this.prepareDescriptorEntry(loDesc, poEntity, lsEntityId, poDescriptor, loContext, poParentEntity);

						loContext.entry = loDesc.entry;
						loDesc.forms = this.isvcPatternResolver.resolveContextualPatterns(loDesc.forms, loContext);
						loDesc.layouts = this.isvcPatternResolver.resolveContextualPatterns(loDesc.layouts, loContext);
						if (loDesc.lists) {
							Object.entries(loDesc.lists).forEach(([psKey, poEntityEntriesListDefinition]: [string, IEntityEntriesListDefinition]) => {
								loDesc.lists[psKey] = {
									...this.isvcPatternResolver.resolveContextualPatterns(ObjectHelper.omit(poEntityEntriesListDefinition, ["item"]), loContext),
									item: poEntityEntriesListDefinition.item
								}
							});
						}
						return loDesc;
					})
				);
			})
		);
	}

	private getDescriptorModel$(
		psEntityId: string,
		poEntityParams?: IEntityDescriptorEntityParams,
		poActivePageManager?: ActivePageManager
	): Observable<Entity | undefined> {
		if (poEntityParams?.model)
			return of(poEntityParams?.model);

		const lbIsCreation: boolean = StringHelper.isBlank(poEntityParams?.guid);

		return lbIsCreation ? of(undefined) :
			this.getModel$(psEntityId, undefined, poActivePageManager);
	}

	private getParentEntry$(
		poDescriptor: IEntityDescriptor,
		poEntity?: Entity,
		poContext?: IEntityDescriptorContext,
		poActivePageManager?: ActivePageManager
	): Observable<Entity | undefined> {
		const lsParentEntityId: string | undefined = this.getParentEntityId(poDescriptor, poContext);

		if (!StringHelper.isBlank(lsParentEntityId))
			return this.getModel$(lsParentEntityId, undefined, poActivePageManager);

		return of(ArrayHelper.getFirstElement(poEntity?.getLinkedEntities(EEntityLinkType.parent)));
	}

	private createDescriptorContext(
		poContext: IEntityDescriptorContext | undefined,
		poDesc: IEntityDescriptor,
		psEntityGuid: string | undefined,
		poDescriptor: IEntityDescriptor,
		poParentEntity?: Entity
	): IEntityDescriptorContext {
		return {
			entry: poDesc.entry,
			parentEntry: poParentEntity,
			entityDescriptor: poDesc,
			guid: psEntityGuid ?? GuidHelper.newGuid(),
			permissions: {
				canCreate: this.hasPermission(poDescriptor, "create"),
				canEdit: this.hasPermission(poDescriptor, "edit"),
				canDelete: this.hasPermission(poDescriptor, "delete")
			},
			...(poContext ?? {}),
			entitiesService: this
		};
	}

	public hasPermission(poDescriptor: IEntityDescriptor, psPermissionType: TCRUDPermissions): boolean;
	public hasPermission(poDescriptor: IEntityDescriptor, psPermissionType: string): boolean;
	public hasPermission(poDescriptor: IEntityDescriptor, psPermissionType: TCRUDPermissions | string): boolean {
		return this.isvcPermissions.evaluatePermission(poDescriptor.permissionScope as EPermissionScopes, psPermissionType);
	}

	private prepareDescriptorEntry(
		poDesc: IEntityDescriptor,
		poModel: Entity | undefined,
		psEntityId: string,
		poDescriptor: IEntityDescriptor,
		poContext: IEntityDescriptorContext,
		poParentEntity?: Entity
	): void {
		const lbIsCreation: boolean = !poModel;
		poDesc.entry = poModel?.clone() ?? ModelResolver.toClass(
			Entity,
			{
				_id: psEntityId,
				...(
					poDescriptor.baseModel ?
						this.isvcPatternResolver.resolveContextualPatterns(poDesc.baseModel, poContext) :
						{}
				)
			}
		);

		if (lbIsCreation) {
			if (poParentEntity) {
				this.isvcEntityLink.cacheLinkToAdd(
					poDesc.entry,
					[poParentEntity],
					[{ [poDesc.entry._id]: EEntityLinkType.child, [poParentEntity._id]: EEntityLinkType.parent }]
				);
			}
			if (ArrayHelper.hasElements(poContext.links)) {
				poContext.links.forEach((poEntityContextLink: IEntityDescriptorContextLink) => {
					this.isvcEntityLink.cacheLinkToAdd(
						poDesc.entry,
						[poEntityContextLink.target],
						[{ [poDesc.entry._id]: poEntityContextLink.type, [poEntityContextLink.target._id]: poEntityContextLink.targetType }]
					);
				})
			}
		}
	}

	public getEntityId(poDescriptor: IEntityDescriptor, psEntityGuid: string | undefined): string {
		return this.isvcPatternResolver.resolveContextualPattern(
			poDescriptor.idPattern,
			{ guid: psEntityGuid ?? GuidHelper.newGuid() }
		) ?? "";
	}

	public getParentEntityId(
		poDescriptor: IEntityDescriptor,
		poContext?: IEntityDescriptorContext
	): string | undefined {
		let lsParentEntityId: string | undefined = poContext?.state?.parentId;
		let lnIndex = 0;

		while (
			lnIndex < (poDescriptor.parent?.idSearchPatterns?.length ?? 0) &&
			StringHelper.isBlank(lsParentEntityId)
		) {
			const lsPattern: string | undefined = poDescriptor.parent?.idSearchPatterns?.[lnIndex];
			if (!StringHelper.isBlank(lsPattern))
				lsParentEntityId = this.isvcPatternResolver.resolveContextualPattern(lsPattern, poContext);
			lnIndex++;
		}

		return lsParentEntityId;
	}

	public getModel$(
		psModelId?: string,
		psDatabaseId?: string,
		poActivePageManager?: ActivePageManager
	): Observable<Entity | undefined> {
		if (StringHelper.isBlank(psModelId))
			return of(undefined);

		return combineLatest([
			this.isvcStore.getOne(
				this.getModelDataSource(psModelId, psDatabaseId, poActivePageManager),
				false
			),
			this.isvcEntityLink.getEntityLinks(psModelId, undefined, undefined, true, poActivePageManager)
		]).pipe(
			mergeMap(([poEntity, paEntityLinks]: [Entity | undefined, EntityLink[]]) => {
				if (poEntity) {
					poEntity.links = paEntityLinks;
					return this.fillEntityWithLinkedEntities$(poEntity, paEntityLinks, poActivePageManager);
				}

				return of(poEntity);
			})
		);
	}

	private fillEntityWithLinkedEntities$(
		poEntity: Entity,
		paEntityLinks: EntityLink[],
		poActivePageManager?: ActivePageManager
	): Observable<Entity> | Observable<undefined> {
		return this.isvcEntityLink.getEntityLinksEntities$(
			[poEntity._id],
			paEntityLinks,
			true,
			false,
			poActivePageManager
		).pipe(
			map((paEntities: Entity[]) => {
				poEntity.linkedEntities = paEntities;
				return poEntity;
			})
		);
	}

	private getModelDataSource(
		psModelId: string,
		psDatabaseId?: string,
		poActivePageManager?: ActivePageManager
	): IDataSourceRemoteChanges<Entity> {
		return {
			databaseId: psDatabaseId,
			role: StringHelper.isBlank(psDatabaseId) ? EDatabaseRole.workspace : undefined,
			viewParams: {
				key: psModelId,
				include_docs: true
			},
			live: true,
			remoteChanges: !!poActivePageManager,
			activePageManager: poActivePageManager,
			baseClass: Entity,
			withExternal: true
		};
	}

	public getModels$(
		paEntityPaths: string[],
		poActivePageManager?: ActivePageManager
	): Observable<Entity[]> {
		if (!ArrayHelper.hasElements(paEntityPaths))
			return of([]);

		const laGetObservables: Observable<Entity[]>[] = [];

		ArrayHelper.groupBy(paEntityPaths, (psPath: string) => Store.getDatabaseIdFromDocumentPath(psPath)).forEach(
			(paPaths: string[], psDatabaseId: string) => {
				if (ArrayHelper.hasElements(paPaths)) {
					laGetObservables.push(this.isvcStore.get(
						this.getMultipleEntitiesDataSource(psDatabaseId, paPaths, poActivePageManager)
					));
				}
			}
		);

		return combineLatest(laGetObservables).pipe(
			map((paResults: Entity[][]) => paResults.flat()),
			mergeMap((paEntities: Entity[]) =>
				this.fillEntitiesWithLinks$(paEntities, poActivePageManager)
			)
		);
	}

	private fillEntitiesWithLinks$(
		paEntities: Entity[],
		poActivePageManager: ActivePageManager | undefined
	): Observable<Entity[]> {
		const laSortedIds: string[] = paEntities.map((poEntity: Entity) => poEntity._id).sort();
		return this.isvcEntityLink.getEntityLinks$(
			laSortedIds,
			undefined,
			undefined,
			true,
			poActivePageManager
		).pipe(
			mergeMap((poLinks: Map<string, EntityLink[]>) => {
				const laLinks: EntityLink[] = MapHelper.valuesToArray(poLinks).flat();
				return this.isvcEntityLink.getEntityLinksEntities$(
					laSortedIds,
					laLinks,
					true,
					false,
					poActivePageManager
				).pipe(
					map((paLinkedEntities: Entity[]) =>
						this.hydrateEntities(paLinkedEntities, laSortedIds, laLinks, paEntities, poLinks)
					)
				);
			})
		);
	}

	private hydrateEntities(
		paLinkedEntities: Entity[],
		paSortedIds: string[],
		paLinks: EntityLink[],
		paEntities: Entity[],
		poLinks: Map<string, EntityLink[]>
	): Entity[] {
		const laEntitiesBySourceId: Map<string, Entity[]> = this.isvcEntityLink.groupEntitiesBySourceId(
			paLinkedEntities,
			this.isvcEntityLink.groupSourceIdsByTargetId(paSortedIds, paLinks)
		);

		paEntities.forEach((poEntity: Entity) => {
			const laEntityLinks: EntityLink[] | undefined = poLinks.get(poEntity._id);
			const laEntities: Entity[] | undefined = laEntitiesBySourceId.get(poEntity._id);

			if (ArrayHelper.hasElements(laEntityLinks))
				poEntity.links = laEntityLinks;

			if (ArrayHelper.hasElements(laEntities))
				poEntity.linkedEntities = laEntities;
		});

		return paEntities;
	}

	private getMultipleEntitiesDataSource(
		psDatabaseId: string,
		paPaths: string[],
		poActivePageManager: ActivePageManager | undefined
	): IDataSourceRemoteChanges<Entity> {
		return {
			databaseId: psDatabaseId,
			role: StringHelper.isBlank(psDatabaseId) ? EDatabaseRole.workspace : undefined,
			viewParams: {
				keys: paPaths.map((psPath: string) => Store.getDocumentIdFromPath(psPath)),
				include_docs: true
			},
			live: true,
			remoteChanges: !!poActivePageManager,
			activePageManager: poActivePageManager,
			baseClass: Entity,
			withExternal: true
		};
	}

	public getModelAsync(
		psModelId?: string,
		psDatabaseId?: string
	): Promise<Entity | undefined> {
		return this.getModel$(psModelId, psDatabaseId).pipe(take(1)).toPromise();
	}

	public getModelsAsync(
		paEntityPaths: string[]
	): Promise<Entity[]> {
		return firstValueFrom(this.getModels$(paEntityPaths));
	}

	/** Retourne la définition de formulaire correspondant à l'id passé en paramètre, `undefined` si non trouvée.
	 * @param poDescriptor
	 * @param psDefinitionId
	 */
	public getDefinition(poDescriptor: IEntityDescriptor, psDefinitionId: string): IFormDefinition | undefined {
		return poDescriptor.forms[psDefinitionId];
	}

	/** Retourne la définition de liste correspondant à l'id passé en paramètre, `undefined` si non trouvée.
	 * @param poDescriptor
	 * @param psDefinitionId
	 */
	public getListDefinition(poDescriptor: IEntityDescriptor, psDefinitionId?: string): IEntityEntriesListDefinition | undefined {
		if (poDescriptor.lists && !StringHelper.isBlank(psDefinitionId))
			return poDescriptor.lists[psDefinitionId];
		return undefined;
	}

	/** Retourne la source de données correspondant à l'id passé en paramètre, `undefined` si non trouvée.
	 * @param poDescriptor
	 * @param psDefinitionId
	 */
	public getDataSource(poDescriptor: IEntityDescriptor, psDefinitionId?: string): IDataSource | undefined {
		if (poDescriptor.dataSources)
			return poDescriptor.dataSources[psDefinitionId ?? Object.keys(poDescriptor.dataSources)[0]];
		return undefined;
	}

	public getStateMachines(poDescriptor: IEntityDescriptor): IIndexedObject<StateMachine> | undefined {
		if (poDescriptor.stateMachines) {
			const loStateMchines: IIndexedObject<StateMachine> = {};
			Object.entries(poDescriptor.stateMachines).map(([psKey, poValue]: [string, StateMachine]) => {
				loStateMchines[psKey] = new StateMachine(poValue);
			});
			return loStateMchines;
		}
		return undefined;
	}

	public navigateToEntityViewAsync(poEntity: Entity, poActivatedRoute: ActivatedRoute): Promise<boolean> {
		return this.navigateToEntityAsync(this.getEntityRoute(poEntity), poActivatedRoute);
	}

	public navigateToEntityEditAsync(poEntity: Entity, poActivatedRoute: ActivatedRoute): Promise<boolean> {
		return this.navigateToEntityAsync(`${this.getEntityRoute(poEntity)}/${ERouteUrlPart.edit}`, poActivatedRoute);
	}

	public navigateToEntityCreationAsync(
		poEntityDesc: IEntityDescriptor,
		poActivatedRoute: ActivatedRoute,
		poCreationParams?: IEntityEntriesListParamsCreateParams
	): Promise<boolean> {
		return this.navigateToEntityAsync(
			`${this.getDescriptorRoute(poEntityDesc)}${ERouteUrlPart.new}`,
			poActivatedRoute,
			poCreationParams
		);
	}

	private navigateToEntityAsync(psRoute: string, poActivatedRoute: ActivatedRoute, poState?: any): Promise<boolean> {
		const laUrlParts: string[] = this.ioRouter.url.split("/");
		const laRouteParts: string[] = psRoute.split("/");

		ArrayHelper.removeElementsByFinder(laRouteParts, (psRoutePart: string, pnIndex: number) =>
			psRoutePart === laUrlParts[laUrlParts.length - 1 - pnIndex]
		);
		return this.ioRouter.navigate(laRouteParts, { relativeTo: poActivatedRoute, state: poState });
	}

	public getEntityDescriptor(poEntity?: IEntity): IEntityDescriptor | undefined {
		let loDescriptor: IEntityDescriptor | undefined;

		if (!StringHelper.isBlank(poEntity?.meta?.entityDescId))
			loDescriptor = this.getDescriptor(this.getEntityDescGuidFromEntityDescId(poEntity?.meta?.entityDescId));
		else if (poEntity) {
			const laDescriptors: IEntityDescriptor[] = MapHelper.valuesToArray(EntitiesService.C_ENTITY_DESCRIPTORS_BY_GUID_CACHE);
			for (let lnIndex = 0; lnIndex < laDescriptors.length; ++lnIndex) {
				const loCacheDescriptor: IEntityDescriptor = laDescriptors[lnIndex];

				if (this.isCorrectEntityDescriptor(loCacheDescriptor, poEntity)) {
					loDescriptor = loCacheDescriptor;
					break;
				}
			}
		}

		return loDescriptor;
	}

	private isCorrectEntityDescriptor(poDescriptor: IEntityDescriptor, poEntity: IEntity): boolean {
		return StringHelper.isValid(poDescriptor.entityMatchPattern) &&
			coerceBooleanProperty(
				this.isvcPatternResolver.resolveContextualPattern(
					poDescriptor.entityMatchPattern,
					{ entry: poEntity }
				)
			);
	}

	public getEntityName(poEntity?: IEntity): string {
		if (!poEntity)
			return "";

		const loDescriptor: IEntityDescriptor | undefined = this.getEntityDescriptor(poEntity);

		return (loDescriptor &&
			this.isvcPatternResolver.resolveContextualPattern(loDescriptor.namePattern, { entry: poEntity })) ??
			"Entité inconnue";
	}

	public getEntityShortName(poEntity?: IEntity): string {
		const loDescriptor: IEntityDescriptor | undefined = this.getEntityDescriptor(poEntity);

		return (loDescriptor &&
			this.isvcPatternResolver.resolveContextualPattern(
				loDescriptor.shortNamePattern ?? loDescriptor.namePattern,
				{ entry: poEntity }
			)) ??
			"Inconnue";
	}

	public getEntityListFieldText(poEntity?: IEntity, poListField?: IListDefinitionsField): string | undefined {
		const loDescriptor: IEntityDescriptor | undefined = this.getEntityDescriptor(poEntity);
		if (!loDescriptor || !poListField)
			return undefined;

		if (!StringHelper.isBlank(poListField.valuePattern))
			return this.isvcPatternResolver.resolveContextualPattern(poListField.valuePattern, { entry: poEntity });
		else if (!StringHelper.isBlank(poListField.key))
			return poEntity?.[poListField.key]?.toString();

		return undefined;
	}

	public getEntityCategory(poEntity: IEntity): IEntityCategory {
		return this.getEntityDescCategory(this.getEntityDescriptor(poEntity));
	}

	public getEntityDescCategory(poEntityDesc?: IEntityDescriptor): IEntityCategory {
		return poEntityDesc?.category ?? { name: "Catégorie inconnue" };
	}

	public getEntityRoute(poEntity: IEntity): string {
		const loDescriptor: IEntityDescriptor | undefined = this.getEntityDescriptor(poEntity);

		return this.getDescriptorRoute(loDescriptor, poEntity);
	}

	private getDescriptorRoute(loDescriptor: IEntityDescriptor | undefined, poEntity?: IEntity): string {
		return (loDescriptor &&
			this.isvcPatternResolver.resolveContextualPattern(
				loDescriptor.routePattern,
				{
					entry: poEntity,
					entryGuid: poEntity ? GuidHelper.extractGuid(poEntity._id) : undefined,
					entityDescriptor: loDescriptor
				}
			)) ??
			"";
	}

	public getEntityAvatar(poEntity: IEntity): IAvatar | undefined {
		const loDescriptor: IEntityDescriptor | undefined = this.getEntityDescriptor(poEntity);

		return loDescriptor?.avatar ?
			{
				size: typeof loDescriptor.avatar.size === "string" ?
					this.isvcPatternResolver.resolveContextualPattern(loDescriptor.avatar.size, { entry: poEntity }) :
					EAvatarSize.medium, // Taille par défaut
				guid: this.isvcPatternResolver.resolveContextualPattern(loDescriptor.avatar.guid, { entry: poEntity }),
				icon: this.isvcPatternResolver.resolveContextualPattern(loDescriptor.avatar.icon, { entry: poEntity })
			} :
			undefined;
	}

	public getEntityDocumentPaths(poEntity: IEntity): string[] {
		const loDescriptor: IEntityDescriptor | undefined = this.getEntityDescriptor(poEntity);
		const leEntityPrefix: EPrefix = IdHelper.getPrefixFromId(poEntity._id);

		return ArrayHelper.hasElements(loDescriptor?.documentPathPatterns) ?
			ArrayHelper.getValidValues(loDescriptor.documentPathPatterns.map((psPattern: string) => this.isvcPatternResolver.resolveContextualPattern(
				psPattern,
				{ entry: poEntity }
			))) :
			loDescriptor ?
				[`${ConfigData.documentsConfiguration?.defaultPathPrefix ? `${ConfigData.documentsConfiguration.defaultPathPrefix}\\` : ""}${leEntityPrefix.replace("_", "")}\\${IdHelper.getGuidFromId(poEntity._id, leEntityPrefix)}`] :
				[];
	}

	public getEntityRole(poEntity: IEntity): EDatabaseRole {
		const loDescriptor: IEntityDescriptor | undefined = this.getEntityDescriptor(poEntity);

		return loDescriptor?.role ?? EDatabaseRole.workspace;
	}

	public showUnsavedEntityPopupAsync(): Promise<boolean> {
		return this.isvcUiMessage.showAsyncMessage(
			new ShowMessageParamsPopup({
				message: "Des modifications ont été réalisées. Si vous continuez, les données modifiées seront perdues. Voulez-vous vraiment continuer ?",
				header: "Modifications non enregistrées",
				buttons: [
					{ text: "Annuler", handler: () => UiMessageService.getFalsyResponse() },
					{ text: "Continuer", handler: () => UiMessageService.getTruthyResponse(), cssClass: "rounded-popup-button" }
				] as AlertButton[],
				backdropDismiss: false
			})
		).pipe(
			mergeMap((poResponse: IUiResponse<boolean, any>) => {
				return of(!!poResponse.response);
			})
		).toPromise();
	}

	/** Récupère toutes les entrées possèdant le `FormDescId` en paramètre et par la vue de la datasource, dans la base donnée par la datasource.
	 * @param poDataSource Donne la base et la vue à utiliser pour trouver les entrées, mode 'live' par défaut.
	 */
	public getEntries$<T extends Entity = Entity>(
		poDataSource?: IDataSource<T>,
		poFilters?: IEntityEntriesListParamsFilters,
		pbIncludeDocs: boolean = true
	): Observable<Entity[]> {
		if (!poDataSource)
			return of([]);

		if (
			!ArrayHelper.hasElements(poDataSource.databasesIds) &&
			StringHelper.isBlank(poDataSource.databaseId) &&
			!poDataSource.role
		) {
			poDataSource.role = EDatabaseRole.workspace;
		}

		if (!poDataSource.baseClass)
			poDataSource.baseClass = Entity as any;
		poDataSource.live = poDataSource.live !== false; // Si on n'a pas mis explicitement un `live === false`, on le met.

		if (poDataSource.viewParams?.endkey)
			poDataSource.viewParams.endkey = `${poDataSource.viewParams?.endkey}${Store.C_ANYTHING_CODE_ASCII}`;

		if (!poDataSource.viewParams)
			poDataSource.viewParams = {};

		poDataSource.viewParams.include_docs = pbIncludeDocs;

		return this.execGetAllEntries$(poDataSource, poFilters);
	}

	/** Exécute la requête qui récupère des données qui pouvant être : une entry (en fonctin de son id), plusieurs entry, ou un formDescriptor.
	 * @param poDataSource paramètres du menu que l'on veut initialiser.
	 * @param psDatabaseId id de la base de données à récupérer.
	 */
	private execGetAllEntries$<T extends Entity = Entity>(
		poDataSource: IDataSource<T>,
		poFilters?: IEntityEntriesListParamsFilters
	): Observable<Entity[]> {
		return defer(() => {
			if (ArrayHelper.hasElements(poFilters?.linkedToIds) && typeof poDataSource.viewParams?.startkey === "string") {
				return this.isvcEntityLink.getLinkedEntityIds(
					poFilters?.linkedToIds,
					[IdHelper.getPrefixFromId(poDataSource.viewParams.startkey)],
					undefined,
					poDataSource?.live
				).pipe(
					tap((poTargetIdsBySourceId: Map<string, string[]>) => {
						if (poDataSource.viewParams) { // On vient modifier la datasource pour filtrer par le tableau d'identifiants
							poDataSource.viewParams.keys = MapHelper.valuesToArray(poTargetIdsBySourceId).flat();
							poDataSource.viewParams.startkey = undefined;
							poDataSource.viewParams.endkey = undefined;
						}
					})
				);
			}

			return of(undefined);
		}).pipe(
			switchMap(() => this.isvcStore.get(poDataSource)),
			catchError(poError => {
				const lsStringifiedDatabases: string = poDataSource.databaseId ? poDataSource.databaseId : JSON.stringify(poDataSource.databasesIds);
				console.error(`FORM.S:: Erreur récupération base de données ${lsStringifiedDatabases} : `, poError);
				return throwError(() => poError);
			})
		);
	}

	public countEntries$<T extends Entity = Entity>(poDataSource?: IDataSource<T>, poFilters?: IEntityEntriesListParamsFilters): Observable<number> {
		return this.getEntries$(poDataSource, poFilters, false).pipe(map((paDocs: IStoreDocument[]) => paDocs.length));
	}

	//#endregion

}
