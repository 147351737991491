<ng-container *ngIf="observableParams.value$ | async as params">
	<ng-container *ngIf="params.layout === 'inline'">
		<calao-inline-list-field-layout [icon]="params.layoutParams.icon || 'people'"
			[addButtonLabel]="params.layoutParams.addButtonLabel || 'Ajouter ...'" [separator]="params.layoutParams.separator"
			(onAddClicked)="onAddClicked()">
			<ng-container *ngIf="observableGroupTags.changes$ | async as tags">
				<calao-deletable-tags-list *ngIf="tags.length > 0" [tags]="tags"
					(onRemoveTagClicked)="onRemoveTagClicked($event)">
				</calao-deletable-tags-list>
			</ng-container>
		</calao-inline-list-field-layout>
	</ng-container>
</ng-container>