import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IDateRange } from '@calaosoft/osapp/components/date/date-range-picker/model/IDateRange';
import { ArrayHelper } from '@calaosoft/osapp/helpers/arrayHelper';
import { DateHelper } from '@calaosoft/osapp/helpers/dateHelper';
import { NumberHelper } from '@calaosoft/osapp/helpers/numberHelper';
import { ObjectHelper } from '@calaosoft/osapp/helpers/objectHelper';
import { StringHelper } from '@calaosoft/osapp/helpers/stringHelper';
import { ESortOrder } from '@calaosoft/osapp/model/ESortOrder';
import { IContactsListParams } from '@calaosoft/osapp/model/contacts/IContactsListParams';
import { ETimetablePattern } from '@calaosoft/osapp/model/date/ETimetablePattern';
import { IEventLinkedEntitiesFilterParams } from '@calaosoft/osapp/modules/calendar-events/models/ievent-linked-entities-filter-params';
import { IEventOccurrenceDateRange } from '@calaosoft/osapp/modules/calendar-events/models/ievent-occurrence-date-range';
import { EEntityLinkType } from '@calaosoft/osapp/modules/entities/models/eentity-link-type';
import { Entity } from '@calaosoft/osapp/modules/entities/models/entity';
import { IEntitySelectorParamsBase } from '@calaosoft/osapp/modules/entities/models/ientity-selector-params-base';
import { ObserveProperty } from '@calaosoft/osapp/modules/observable/decorators/observe-property.decorator';
import { ObservableProperty } from '@calaosoft/osapp/modules/observable/models/observable-property';
import { ISelectorParams } from '@calaosoft/osapp/modules/selector/selector/ISelectorParams';
import { ESortSelectorSize } from '@calaosoft/osapp/modules/sort/models/esort-selector-size';
import { ISortOption } from '@calaosoft/osapp/modules/sort/models/isort-option';
import { DestroyableComponentBase } from '@calaosoft/osapp/modules/utils/components/destroyable-component-base';
import { IRange } from '@calaosoft/osapp/modules/utils/models/models/irange';
import { secure } from '@calaosoft/osapp/modules/utils/rxjs/operators/secure';
import { Observable, combineLatest } from 'rxjs';
import { distinctUntilChanged, filter, first, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { IEventsFilterValues } from '../../models/ievents-filter-values';
import { ReminderOccurrence } from '../../models/reminder-occurrence';
import { TaskOccurrence } from '../../models/task-occurrence';

interface IEventFilterBarFilterValues extends Omit<IEventsFilterValues, "dateRange"> {
	dateRange?: IEventOccurrenceDateRange;
}

@Component({
	selector: 'trade-events-filter-bar',
	templateUrl: './events-filter-bar.component.html',
	styleUrls: ['./events-filter-bar.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class EventsFilterBarComponent<T extends IEventFilterBarFilterValues> extends DestroyableComponentBase implements OnInit {

	//#region FIELDS

	/** Événement lors du changement des filtres. */
	@Output("onFilterValuesChange") private readonly moFilterValuesChangeEvent = new EventEmitter<T>();
	/** Événement lors du changement des filtres temporaires. */
	@Output("onTmpFilterValuesChange") private readonly moTmpFilterValuesChangeEvent = new EventEmitter<T>();
	/** Événement lors du changement du compteur du nombre de filtres appliqués. */
	@Output("onTotalNbFiltersChange") private readonly moTotalNbFiltersChange = new EventEmitter<number>();

	private moObservableDefaultFilterValues = new ObservableProperty<T | undefined>();

	//#endregion

	//#region PROPERTIES

	public readonly contactsSelectorParams: IContactsListParams = {
		contactsSelectorParams: {
			userContactVisible: true
		},
		contactsById: true
	};

	public readonly defaultSort: ESortOrder = ESortOrder.ascending;
	public readonly sortSize = ESortSelectorSize.small;

	/** Clef de tri par défaut. */
	@Input() public defaultBy?: keyof TaskOccurrence;
	@ObserveProperty<EventsFilterBarComponent<T>>({ sourcePropertyKey: "defaultBy" })
	public readonly observableDefaultBy = new ObservableProperty<keyof TaskOccurrence>("priority");

	/** Valeur des filtres. */
	@Input() public filterValues?: IEventFilterBarFilterValues | null;
	@ObserveProperty<EventsFilterBarComponent<T>>({ sourcePropertyKey: "filterValues" })
	public readonly observableFilterValues = new ObservableProperty<IEventFilterBarFilterValues>();

	/** Nombre de résultats si les filtres sont validés. */
	@Input() public nbTmpResults?: number;
	@ObserveProperty<EventsFilterBarComponent<T>>({ sourcePropertyKey: "nbTmpResults" })
	public readonly observableNbTmpResults = new ObservableProperty<number>();

	/** Date minimum. */
	@Input() public minDate?: Date;
	@ObserveProperty<EventsFilterBarComponent<T>>({ sourcePropertyKey: "minDate" })
	public readonly observableMinDate = new ObservableProperty<Date>();

	/** Date maximum. */
	@Input() public maxDate?: Date;
	@ObserveProperty<EventsFilterBarComponent<T>>({ sourcePropertyKey: "maxDate" })
	public readonly observableMaxDate = new ObservableProperty<Date>();

	/** Tableau des options de tri dans la bottomsheet. */
	@Input() public sortOptions?: ReadonlyArray<ISortOption<keyof TaskOccurrence | ReminderOccurrence>>;
	@ObserveProperty<EventsFilterBarComponent<T>>({ sourcePropertyKey: "sortOptions" })
	public readonly observableSortOptions = new ObservableProperty<ReadonlyArray<ISortOption<keyof TaskOccurrence | ReminderOccurrence>>>();

	/** `true` pour masquer le tag de filtre supplémentaire, sinon `false`. */
	@Input() public hideMoreFiltersTag?: boolean;
	@ObserveProperty<EventsFilterBarComponent<T>>({ sourcePropertyKey: "hideMoreFiltersTag" })
	public readonly observableHideMoreFiltersTag = new ObservableProperty<boolean>(false);

	public readonly dateRangeLabel$: Observable<string | undefined> = this.getDateRangeLabel$().pipe(
		secure(this)
	);

	public readonly nbFiltersLabel$: Observable<string | undefined> = this.getNbFiltersLabel$().pipe(
		secure(this)
	);

	public readonly nbMoreFiltersLabel$: Observable<string | undefined> = this.getNbMoreFiltersLabel$().pipe(
		secure(this)
	);

	/** Masque le sélecteur de clients si `true`, par défaut est `false`. */
	@Input() public hideCustomerSelector?: boolean;
	@ObserveProperty<EventsFilterBarComponent<T>>({ sourcePropertyKey: "hideCustomerSelector" })
	public readonly observableHideCustomerSelector = new ObservableProperty<boolean>(false);

	/** Masque le sélecteur de dates si `true`, par défaut est `false`. */
	@Input() public hideDateSelector?: boolean;
	@ObserveProperty<EventsFilterBarComponent<T>>({ sourcePropertyKey: "hideDateSelector" })
	public readonly observableHideDateSelector = new ObservableProperty<boolean>(false);

	/** Masque l'input de filtrage par lieu si `true`, par défaut est `false`. */
	@Input() public hidePlaceSelector?: boolean;
	@ObserveProperty<EventsFilterBarComponent<T>>({ sourcePropertyKey: "hidePlaceSelector" })
	public readonly observableHidePlaceSelector = new ObservableProperty<boolean>(false);

	/** Masque le sélecteur de dates pour le filtrage des deadlines si `true`, par défaut est `false`. */
	@Input() public hideDeadlineSelector?: boolean;
	@ObserveProperty<EventsFilterBarComponent<T>>({ sourcePropertyKey: "hideDeadlineSelector" })
	public readonly observableHideDeadlineSelector = new ObservableProperty<boolean>(false);

	/** Définition des couleurs par valeur. */
	public readonly observableColors = new ObservableProperty<Record<string, string>>(this.getColors());

	/** `true` si le filtrage peut être validé, sinon `false`. */
	public readonly observableCanValidate = new ObservableProperty<boolean>(true);

	/** Configuration du composant osapp-selector pour le filtrage par type de'évènement. */
	@Input() public typeSelectorParams?: ISelectorParams;
	@ObserveProperty<EventsFilterBarComponent<T>>({ sourcePropertyKey: "typeSelectorParams" })
	public readonly observableTypeSelectorParams = new ObservableProperty<ISelectorParams>();

	//#endregion

	//#region METHODS

	public ngOnInit(): void {
		this.observableFilterValues.value$.pipe(
			first(),
			tap((poFilterValues: T) => this.moObservableDefaultFilterValues.value = poFilterValues),
			secure(this)
		).subscribe();

		this.nbFiltersLabel$.pipe(
			mergeMap(() => this.emitTotalFiltersCount$()),
			secure(this)
		).subscribe();
	}

	public onFilterValuesChange(poFilterValues: T): void {
		this.moFilterValuesChangeEvent.emit(this.observableFilterValues.value = poFilterValues);
	}

	public onTmpFilterValuesChange(poTmpFilterValues: T): void {
		this.moTmpFilterValuesChangeEvent.emit(poTmpFilterValues);
	}

	private getDateRangeLabel$(): Observable<string | undefined> {
		return this.observableFilterValues.value$.pipe(
			filter((poEventsFilterValues: IEventsFilterValues) => !!poEventsFilterValues.dateRange),
			map((poEventsFilterValues: IEventsFilterValues) => poEventsFilterValues.dateRange),
			distinctUntilChanged(ObjectHelper.areEqual),
			switchMap((poRange: IRange<Date>) =>
				this.moObservableDefaultFilterValues.value$.pipe(map((poDefaultFilterValues?: T) => {
					if (
						DateHelper.areEqual(poDefaultFilterValues?.dateRange?.from, poRange?.from) &&
						DateHelper.areEqual(poDefaultFilterValues?.dateRange?.to, poRange?.to)
					)
						return undefined;
					else
						return `${poRange.from ? DateHelper.transform(poRange.from, ETimetablePattern.dd_MM_yy_slash) : "~"} - ${poRange.to ? DateHelper.transform(poRange.to, ETimetablePattern.dd_MM_yy_slash) : "-"}`;
				}))
			)
		);
	}

	private getNbFiltersLabel$(): Observable<string | undefined> {
		return combineLatest([this.observableFilterValues.value$, this.moObservableDefaultFilterValues.value$]).pipe(
			map(([poEventsFilterValues, poDefaultFilterValues]: [T, T]) =>
				+(ArrayHelper.hasElements(poEventsFilterValues.linkedEntities?.ids) && !this.observableHideCustomerSelector.value) +
				+ArrayHelper.hasElements(poEventsFilterValues.participantIds) +
				+!ObjectHelper.isEmpty(poEventsFilterValues.dateRange) +
				+!StringHelper.isBlank(poEventsFilterValues.place) +
				+(
					poDefaultFilterValues?.priority !== poEventsFilterValues.priority &&
					NumberHelper.isValid(poEventsFilterValues.priority)
				)
			),
			map((pnCounter: number) => {
				if (pnCounter > 0)
					return `${pnCounter}`;
				return undefined;
			})
		);
	}

	private getNbMoreFiltersLabel$(): Observable<string | undefined> {
		return combineLatest([this.observableFilterValues.value$, this.moObservableDefaultFilterValues.value$]).pipe(
			map(([poEventsFilterValues, poDefaultFilterValues]: [T, T]) =>
				+(
					!ArrayHelper.areArraysEqual(poDefaultFilterValues?.linkedEntities?.ids, poEventsFilterValues.linkedEntities?.ids) &&
					ArrayHelper.hasElements(poEventsFilterValues.linkedEntities?.ids) &&
					!this.observableHideCustomerSelector.value
				) +
				+(
					!ArrayHelper.areArraysEqual(poDefaultFilterValues?.participantIds, poEventsFilterValues.participantIds) &&
					ArrayHelper.hasElements(poEventsFilterValues.participantIds)
				) +
				+(
					poDefaultFilterValues?.place !== poEventsFilterValues.place &&
					!StringHelper.isBlank(poEventsFilterValues.place)
				) +
				+(
					poDefaultFilterValues?.priority !== poEventsFilterValues.priority &&
					NumberHelper.isValid(poEventsFilterValues.priority)
				)
			),
			map((pnCounter: number) => {
				if (pnCounter > 0)
					return `+${pnCounter}`;
				return undefined;
			})
		);
	}

	private emitTotalFiltersCount$(): Observable<void> {
		return combineLatest([this.observableFilterValues.value$, this.nbFiltersLabel$]).pipe(
			map(([poEventsFilterValues, psLabel]: [T, string]) => {
				let lnTotalFilters = Number(psLabel).valueOf();
				if (!StringHelper.isBlank(poEventsFilterValues.title) && NumberHelper.isStringNumber(psLabel)) {
					lnTotalFilters = lnTotalFilters + 1;
				}

				this.moTotalNbFiltersChange.emit(lnTotalFilters);
			})
		);
	}

	private getColors(): Record<string, string> {
		return {
			"1": "--ion-color-danger",
			"2": "--ion-color-danger",
			"3": "--ion-color-task",
			"4": "--ion-color-task",
			"5": "--ion-color-success"
		};
	}

	public updateCanValidate(poRange: IDateRange) {
		this.observableCanValidate.value = DateHelper.isDate(poRange.from) && DateHelper.isDate(poRange.to);
	}

	public onSelectionChanged(poEvent: string[], poObservableValue: ObservableProperty<string[]>): void {
		poObservableValue.value = [...poEvent];
	}

	public getBusinessSelectorParams(poObservableValue: ObservableProperty<IEventLinkedEntitiesFilterParams>): IEntitySelectorParamsBase {
		return {
			entityDescId: "business",
			placeholder: "Aucun business sélectionné",
			preselectedEntityId: ArrayHelper.getFirstElement(poObservableValue.value?.ids),
		} as any; // Ajouter le mode "inline" au moment voulu.
	}

	public onSelectedBusinessChanged(poSelectedEntity: Entity | undefined, poObservableValue: ObservableProperty<IEventLinkedEntitiesFilterParams>): void {
		poObservableValue.value = {
			ids: poSelectedEntity ? [poSelectedEntity._id] : [],
			type: EEntityLinkType.relative
		}
	}

	//#endregion

}
