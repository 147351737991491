import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { StringHelper } from '../../../../../../helpers/stringHelper';
import { EInput } from '../../../../../../model/uiMessage/EInput';
import { IUiResponse } from '../../../../../../model/uiMessage/IUiResponse';
import { ShowMessageParamsToast } from '../../../../../../services/interfaces/ShowMessageParamsToast';
import { UiMessageService } from '../../../../../../services/uiMessage.service';
import { ObserveProperty } from '../../../../../observable/decorators/observe-property.decorator';
import { ObservableProperty } from '../../../../../observable/models/observable-property';
import { TelephoneHelper } from '../../../../../telephone';
import { Queue } from '../../../../../utils/queue/decorators/queue.decorator';
import { FormHelper } from '../../../../helpers/formHelper';
import { FieldBase } from '../../../../models/FieldBase';
import { ITemplateOptions } from '../../../../models/fieldComponent/ITemplateOptions';
import { IOneTimeFilledField } from '../../../../models/fieldComponent/ione-time-filled-field';
import { IInputField } from '../../../../models/fieldComponent/specifications/IInputField';
import { FormsService } from '../../../../services/forms.service';
import { IInlineFieldLayoutParams } from '../inline-field-layout/models/iinline-field-layout-params';
import { IInlineField } from '../inline-field-layout/models/iinlineField';

@Component({
	templateUrl: './inputField.component.html',
	styleUrls: ['./inputs.component.scss', './input-field.component.scss']
})
export class InputFieldComponent extends FieldBase<string | number> implements IInputField, OnInit, IOneTimeFilledField, IInlineField {

	//#region FIELDS

	/** Nombre maximum de chiffres pour un numéro de téléphone (10 chiffres, ou un préfixe (+33) et 9 chiffres). */
	private static readonly C_TEL_MAX_DIGITS = 16;

	//#endregion

	//#region PROPERTIES

	@ViewChild("input") public input: ElementRef;
	@ViewChild("inputInline") public inputInline: ElementRef;

	/** Valeur réellement affichée. */
	private msDisplayValue: string | number;

	public get displayValue(): string | number {
		return this.msDisplayValue;
	}
	public set displayValue(psDisplayValue: string | number) {
		if (this.msDisplayValue !== psDisplayValue) {
			this.msDisplayValue = psDisplayValue;
			this.onModelChanged();
		}
	}

	/** Définit la clavier à afficher pour la navigateur et des critères de gestion supplémentaire sont appliqués si le type est `tel`. */
	public inputType: string = EInput.text;
	/** `true` si la valeur attendue est un numéro de téléphone. */
	public isTel = false;
	/** Taille max de caractères sur le champ. */
	public maxLength: number;
	/** Indique quel type de champ on traite. */
	public type: string;
	/** Paramètres supplémentaires du champ. */
	public templateOptions: ITemplateOptions<never>;

	public wasFilled: boolean;

	/** Style de layout à afficher (undefined par défaut). */
	@Input() public layout: "inline";
	@ObserveProperty<InputFieldComponent>({ sourcePropertyKey: "layout" })
	public readonly observableLayout = new ObservableProperty<"inline">();

	/** Paramètres d'affichage à passer au layout. */
	@Input() public layoutParams: IInlineFieldLayoutParams;
	@ObserveProperty<InputFieldComponent>({ sourcePropertyKey: "layoutParams" })
	public readonly observableLayoutParams = new ObservableProperty<IInlineFieldLayoutParams>();

	/** Masque le libellé du champs dans le layout si `true` et que le champs est renseigné. */
	@Input() public hideLabelWhenFilled: boolean;
	@ObserveProperty<InputFieldComponent>({ sourcePropertyKey: "hideLabelWhenFilled" })
	public readonly observableHideWhenFilled = new ObservableProperty<boolean>();

	/** Masque le libellé du champs si `true`. */
	public readonly observableHideLabel = new ObservableProperty<boolean>(false);

	//#endregion

	//#region METHODS

	constructor(
		private readonly isvcUiMessage: UiMessageService,
		psvcForms: FormsService
	) {
		super(psvcForms);
	}

	public override ngOnInit(): void {
		super.ngOnInit();

		this.wasFilled = !!this.fieldValue;

		if (this.to.type) {

			if (this.to.type === EInput.zipCode)
				this.inputType = EInput.text;
			else
				this.inputType = this.to.type;

			if (this.inputType === EInput.number)
				this.msDisplayValue = this.fieldValue;
			else if (this.to.type === EInput.tel)
				this.initTelValue();
			else
				this.msDisplayValue = this.fieldValue ? this.fieldValue : "";
		}
		else
			this.msDisplayValue = this.fieldValue ? this.fieldValue : "";

		if (this.to.data && !this.to.disabled)
			this.to.disabled = !FormHelper.canBeFilled(this.to.data.oneTimeFilled, this, this.fieldValue);

		if (this.input && this.to.focus)
			this.input.nativeElement.focus();

		const loInlineParams: IInlineField = this.to as IInlineField;

		this.observableLayout.value = loInlineParams.layout;
		this.observableLayoutParams.value = loInlineParams.layoutParams;
		this.observableHideWhenFilled.value = !!loInlineParams.hideLabelWhenFilled;
		this.observableHideLabel.value = this.observableHideWhenFilled.value && this.wasFilled;
	}

	/** Initialise des attributs de classe si le type de la valeur est `tel`. */
	public initTelValue(): void {
		this.isTel = true;
		this.maxLength = InputFieldComponent.C_TEL_MAX_DIGITS;
		this.setTelValue(this.fieldValue as string);
	}

	/** Récupère un numéro de téléphone dans n'importe quel format, et l'applique à la variable `displayValue` dans un format utilisateur `06...`. */
	private setTelValue(psValue: string): void {
		if (psValue && psValue.startsWith("+33"))
			this.msDisplayValue = `0${psValue.slice(3, psValue.length)}`;
		else
			this.msDisplayValue = psValue;
	}

	public onModelChanged(): void {
		if (this.inputType === EInput.number) {
			const lnNewValue = new Number(this.fieldValue).valueOf();
			this.fieldValue = lnNewValue > 0 ? lnNewValue : undefined;
		}

		this.observableHideLabel.value = this.observableHideWhenFilled.value && !StringHelper.isBlank(`${this.observableFieldValue.value}`);

		if (this.inputType === EInput.tel) {
			let lsPhoneNumber = (this.inputInline.nativeElement.value as string).trim();
			this.displayValue = TelephoneHelper.formatPhoneNumber(lsPhoneNumber);
		}

		this.markAsDirty();
	}

	public onInputClicked(): void {
		if (this.to.disabled && !StringHelper.isBlank(this.to.data?.disabledMessage))
			this.showToast$().subscribe();
	}

	@Queue<
		InputFieldComponent,
		Parameters<InputFieldComponent["showToast$"]>,
		ReturnType<InputFieldComponent["showToast$"]>
	>({
		excludePendings: true
	})
	private showToast$(): Observable<IUiResponse<any>> {
		return this.isvcUiMessage.showAsyncMessage(
			new ShowMessageParamsToast({ message: this.to.data.disabledMessage })
		);
	}

	//#endregion
}