<ng-container *ngIf="observableParams.value$ | async as params">
	<ng-container *ngIf="!params.readOnly">
		<calao-inline-list-field-layout [icon]="layoutParams.icon || 'person'"
			[addButtonLabel]="$any(layoutParams).addButtonLabel || 'Ajouter ...'" [separator]="layoutParams.separator"
			(onAddClicked)="onSelectEntityClickedAsync()">
			<ng-container *ngIf="observableTags.changes$ | async as tags">
				<calao-deletable-tags-list *ngIf="tags.length > 0" [tags]="tags" (onRemoveTagClicked)="removeEntity($event.id)">
				</calao-deletable-tags-list>
			</ng-container>
		</calao-inline-list-field-layout>
	</ng-container>

	<ng-container *ngIf="params.readOnly">
		<ion-label *ngIf="observableModels.changes$ | async as entities" class="txt-contacts-list">
			{{ getEntitiesName(entities) }}
		</ion-label>
	</ng-container>
</ng-container>