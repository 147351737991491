import { Injectable } from '@angular/core';
import { ArrayHelper } from '../../../helpers/arrayHelper';
import { IdHelper } from '../../../helpers/idHelper';
import { StoreHelper } from '../../../helpers/storeHelper';
import { StringHelper } from '../../../helpers/stringHelper';
import { WorkspaceService } from '../../../services/workspace.service';
import { Entity } from '../../entities/models/entity';
import { IEntity } from '../../entities/models/ientity';
import { EntitiesService } from '../../entities/services/entities.service';
import { DmsFileHelper } from '../helpers/dmsFileHelper';
import { IDmsMeta } from './IDmsMeta';
import { IMetaEntityBuilderBase } from './IMetaEntityBuilderBase';

@Injectable()
export class MetaEntityBuilderBase implements IMetaEntityBuilderBase {

	//#region FIELDS

	protected static readonly C_WS_ID = "wsId";
	protected static readonly C_ENTITY_ID = "entityId";
	protected static readonly C_SUBTYPE = "SUBTYPE";
	protected static readonly C_DOCUMENT_PATHS = "paths";
	protected static readonly C_DIV = "DIV";
	protected static readonly C_PATH_SEPARATOR = ";";

	//#endregion FIELDS

	//#region METHODS

	constructor(
		protected readonly isvcWorkspace: WorkspaceService,
		protected readonly isvcEntities: EntitiesService
	) { }

	/** @implements */
	public match(psDocumentId: string): boolean {
		return true; // Pour le builder de base, tous les documents correspondent.
	}

	/** @implements */
	public async prepareMeta(poEntity: Entity, poMeta: IDmsMeta): Promise<IDmsMeta> {
		if (!ArrayHelper.hasElements(poMeta.attributes)) {

			poMeta.attributes = [
				{ name: MetaEntityBuilderBase.C_WS_ID, value: this.isvcWorkspace.getCurrentWorkspaceId(true) },
				{ name: MetaEntityBuilderBase.C_ENTITY_ID, value: await this.getEntityIdAsync(poEntity) },
				{ name: `${IdHelper.getPrefixFromId(poEntity._id).replace("_", "")}Id`, value: poEntity._id }, // Le nom de l'attribut est généré à partir du préfix (ex. : cont_ => contId, conv_ => convId)
				{ name: MetaEntityBuilderBase.C_SUBTYPE, value: poMeta.documentSubType ?? this.getEntityDocumentSubType(poEntity) }
			];

			if (!ArrayHelper.hasElements(poMeta.paths)) {
				const laDocumentPaths = this.getEntityDocumentPaths(poEntity, poMeta.documentSubType);

				if (ArrayHelper.hasElements(laDocumentPaths)) {
					poMeta.attributes.push({ name: MetaEntityBuilderBase.C_DOCUMENT_PATHS, value: laDocumentPaths.join(MetaEntityBuilderBase.C_PATH_SEPARATOR) });
					poMeta.paths = laDocumentPaths;
				}
			}
			else
				poMeta.attributes.push({ name: MetaEntityBuilderBase.C_DOCUMENT_PATHS, value: poMeta.paths?.join(MetaEntityBuilderBase.C_PATH_SEPARATOR) });
		}

		if (StringHelper.isBlank(poMeta.documentType))
			poMeta.documentType = this.getEntityDocumentType(poEntity);

		return poMeta;
	}

	/** Permet de récupérer l'identifiant de l'entité qui sera considérée comme propriétaire du document.
	 * @param poEntity
	 * @returns
	 */
	protected getEntityIdAsync(poEntity: IEntity): Promise<string> {
		return Promise.resolve(poEntity._id);
	}

	/** Permet de récupérer le type de document.
	 * @param poEntity
	 * @returns
	 */
	protected getEntityDocumentType(poEntity: IEntity): string {
		return DmsFileHelper.getDefaultDocumentType();
	}

	/** Permet de récupérer le chemin de classification du document DMS parmi les documents applicatifs.
	 * @param poEntity
	 * @returns null si aucun path n'est défini pour l'entité, array de chemin sinon.
	 */
	protected getEntityDocumentPaths(poEntity: IEntity, psDocumentSubType?: string): string[] {
		return this.isvcEntities.getEntityDocumentPaths(poEntity).map(
			(psPath: string) => StringHelper.isBlank(psDocumentSubType) ? `${psPath}\\photo` : `${psPath}\\${psDocumentSubType}`
		);
	}

	/** Permet de récupérer le type de document.
	 * @param poEntity
	 * @returns
	 */
	protected getEntityDocumentSubType(poEntity: IEntity): string {
		return MetaEntityBuilderBase.C_DIV;
	}

	/** Permet de récupérer l'identifiant du conteneur de l'entité propriétaire du document.
	 * @param poEntity
	 * @returns
	 */
	protected getEntityContainerId(poEntity: IEntity): string {
		return this.isvcWorkspace.getWorkspaceIdFromModel(poEntity) ??
			ArrayHelper.getFirstElement(StoreHelper.getDatabaseRoles(StoreHelper.getDatabaseIdFromCacheData(poEntity)));
	}

	//#endregion

}