import { ObservableProperty } from "../../observable/models/observable-property";
import { Document } from '../models/document';

export class DocumentsCache {

	//#region PROPERTIES

	/** Map des emplacements de tous les documents (peu importe les permissions) par leur id. */
	public allDocumentsPathsById = new Map<string, string[]>();

	/** Map des documents lisible par id. */
	public documentsById = new Map<string, Document>();

	/**
	 * Map des documents lisible par l'utilisateur classé par son emplacement strict.
	 * Si un document à pour emplacement `internal/frais` alors il sera rangé uniquement dans `internal/frais`.
	 */
	public observableDocumentsByEndPath = new ObservableProperty<Map<string, Document[]>>(new Map<string, Document[]>());
	/**
	 * Map des documents lisible par l'utilisateur classé par son emplacement non strict.
	 * Si un document à pour emplacement `internal/frais` alors il sera rangé dans `internal` et `internal/frais`.
	 */
	public observableDocumentsByPath = new ObservableProperty<Map<string, Document[]>>(new Map<string, Document[]>());

	//#endregion PROPERTIES

	//#region METHODS

	public init(paAllDocuments: Document[], paReadableDocuments: Document[]): void {
		this.allDocumentsPathsById = new Map<string, string[]>(paAllDocuments.map((poDocuemnt: Document) => [poDocuemnt._id, poDocuemnt.paths]));

		const loTempDocumentsById = new Map<string, Document>();
		const loTempDocumentsByEndPath = new Map<string, Document[]>();
		const loTempDocumentsByPath = new Map<string, Document[]>();

		paReadableDocuments.forEach((poDocument: Document) => {
			loTempDocumentsById.set(poDocument._id, poDocument);

			poDocument.paths.forEach((psEndPath: string) => {
				const laEndPathDocuments: Document[] | undefined = loTempDocumentsByEndPath.get(psEndPath);
				if (laEndPathDocuments)
					laEndPathDocuments.push(poDocument);
				else
					loTempDocumentsByEndPath.set(psEndPath, [poDocument]);

				const laSplittedEndPathParts: string[] = psEndPath.split("/");
				for (let lnIndex = 0; lnIndex < laSplittedEndPathParts.length; ++lnIndex) {
					const psPath: string = laSplittedEndPathParts.slice(0, lnIndex + 1).join("/");
					const laPathDocuments: Document[] | undefined = loTempDocumentsByPath.get(psPath);
					if (laPathDocuments)
						laPathDocuments.push(poDocument);
					else
						loTempDocumentsByPath.set(psPath, [poDocument]);

					const laRootDocuments: Document[] | undefined = loTempDocumentsByPath.get("");
					if (laRootDocuments)
						laRootDocuments.push(poDocument);
					else
						loTempDocumentsByPath.set("", [poDocument]);
				}
			});
		});

		this.documentsById = loTempDocumentsById;
		this.observableDocumentsByEndPath.value = loTempDocumentsByEndPath;
		this.observableDocumentsByPath.value = loTempDocumentsByPath;
	}

	public clear(): void {
		this.documentsById.clear();
		this.allDocumentsPathsById.clear();
		this.observableDocumentsByEndPath.value = undefined;
		this.observableDocumentsByPath.value = undefined;
	}

	//#endregion METHODS

}