import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { StringHelper } from '../../../../../helpers/stringHelper';
import { ObserveProperty } from '../../../../observable/decorators/observe-property.decorator';
import { ObservableProperty } from '../../../../observable/models/observable-property';
import { ESliderBoundsPosition } from '../../../models/eslider-bounds-position';
import { FieldBase } from '../../../models/FieldBase';
import { ISliderFieldParams } from '../../../models/ISliderFieldParams';
import { FormsService } from '../../../services/forms.service';
import { IInlineFieldLayoutParams } from './inline-field-layout/models/iinline-field-layout-params';
import { IInlineField } from './inline-field-layout/models/iinlineField';

/** Champs contenant un slider. */
@Component({
	templateUrl: './sliderField.component.html',
	styleUrls: ['./sliderField.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SliderFieldComponent extends FieldBase<number> implements OnInit, IInlineField {

	//#region PROPERTIES

	public readonly observableParams = new ObservableProperty<ISliderFieldParams>();
	public readonly observableBubbleColor = new ObservableProperty<string>("background-color: var(--ion-color-primary)");

	/** Style de layout à afficher (undefined par défaut). */
	public layout: "inline";
	@ObserveProperty<SliderFieldComponent>({ sourcePropertyKey: "layout" })
	public readonly observableLayout = new ObservableProperty<"inline">();

	/** Paramètres d'affichage à passer au layout. */
	public layoutParams: IInlineFieldLayoutParams;
	@ObserveProperty<SliderFieldComponent>({ sourcePropertyKey: "layoutParams" })
	public readonly observableLayoutParams = new ObservableProperty<IInlineFieldLayoutParams>();

	/** Masque le libellé du champs dans le layout si `true` et que le champs est renseigné. */
	public hideLabelWhenFilled: boolean;
	@ObserveProperty<SliderFieldComponent>({ sourcePropertyKey: "hideLabelWhenFilled" })
	public readonly observableHideWhenFilled = new ObservableProperty<boolean>();

	public ESliderBoundsPosition = ESliderBoundsPosition;

	//#endregion PROPERTIES

	//#region METHODS

	constructor(psvcForms: FormsService) {
		super(psvcForms);
	}

	/** Endroit où initialiser le composant après sa création. */
	public override ngOnInit(): void {
		super.ngOnInit();
		const loParams: ISliderFieldParams = this.props.data as ISliderFieldParams;
		this.observableParams.value = loParams;
		this.observableLayout.value = loParams.layout;
		this.observableLayoutParams.value = loParams.layoutParams;
		this.observableHideWhenFilled.value = loParams.hideLabelWhenFilled;

		// Initialisation de la valeur de retour du slider (à la valeur minimale, soit 0 par défaut)
		if (!this.fieldValue)
			this.fieldValue = this.observableParams.value.defaultValue ?? this.observableParams.value.minValue;

		if (this.observableParams.value.readOnly)
			this.observableBubbleColor.bind(this.getBubbleColor$(), this);
	}

	public onModelChanged(poEvent: Event): void {
		const lnValue: number = (poEvent as CustomEvent).detail.value;
		if (lnValue !== this.fieldValue) {
			this.fieldValue = lnValue;
			this.markAsDirty();
		}
	}

	private getBubbleColor$(): Observable<string> {
		return this.observableFieldValue.value$.pipe(
			mergeMap((pnValue: number) => {
				if (this.observableParams.value?.colors) {
					const lsColor: string | undefined = this.observableParams.value?.colors[pnValue];
					if (lsColor)
						return of(`background-color: var(${lsColor}); color: var(${lsColor}-contrast);`);
				}

				return of("background-color: var(--ion-color-primary); color: var(--ion-color-primary-contrast);");
			})
		);
	}

	public getFieldLabel(pnValue: number, poParams: ISliderFieldParams): string {
		const lsLabel: string | undefined = poParams.labels?.[pnValue];
		return `${pnValue}${StringHelper.isBlank(lsLabel) ? "" : `, ${lsLabel}`}`;
	}

	//#endregion
}