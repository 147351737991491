import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EMPTY, Observable, of } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { ArrayHelper } from '../../../../../helpers/arrayHelper';
import { IContact } from '../../../../../model/contacts/IContact';
import { IGroup } from '../../../../../model/contacts/IGroup';
import { IGroupSelection } from '../../../../../model/contacts/IGroupSelection';
import { IGroupsChecklistParams } from '../../../../../model/contacts/IGroupsChecklistParams';
import { Group } from '../../../../../model/contacts/group';
import { ERouteUrlPart } from '../../../../../model/route/ERouteUrlPart';
import { GroupsService } from '../../../../../services/groups.service';
import { ShowMessageParamsToast } from '../../../../../services/interfaces/ShowMessageParamsToast';
import { UiMessageService } from '../../../../../services/uiMessage.service';
import { Contact } from '../../../../contacts/models/contact';
import { EPermissionScopes } from '../../../../permissions/models/epermission-scopes';
import { TCRUDPermissions } from '../../../../permissions/models/tcrud-permissions';
import { PermissionsService } from '../../../../permissions/services/permissions.service';
import { FieldBase } from '../../../models/FieldBase';
import { FormsService } from '../../../services/forms.service';

@Component({
	templateUrl: './groups-checklist-field.component.html',
	styleUrls: ['./groups-checklist-field.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class GroupsChecklistFieldComponent extends FieldBase<Group[] | Group, Contact> implements OnInit {

	//#region FIELDS

	/** Url de la page courante. */
	private msCurrentUrl: string;
	/** Indique si les groupes présélectionnés sont initialisés. */
	private mbPreselectedGroupsInit: boolean;

	//#endregion

	//#region PROPERTIES

	/** Paramètres possibles pour le composant. */
	public params: IGroupsChecklistParams<Group>;
	/** Indique si l'utilisateur a les droits de création/édition avec les groupes ou non. */
	public hasPermission: boolean;

	//#endregion

	//#region METHODS

	constructor(
		private readonly isvcGroups: GroupsService,
		private readonly isvcUiMessageService: UiMessageService,
		private readonly isvcPermissions: PermissionsService,
		psvcForms: FormsService,
		poChangeDetectorRef: ChangeDetectorRef,
		poRouter: Router
	) {
		super(psvcForms, poChangeDetectorRef);

		this.msCurrentUrl = poRouter.url;
	}

	public override ngOnInit(): void {
		super.ngOnInit();
		this.params = this.to.data;

		if (!this.params.model)
			this.params.model = this.model as IContact;

		this.initHasPermission().pipe(
			tap((pbHasPermissions: boolean) => {
				this.hasPermission = pbHasPermissions;
				this.detectChanges();
			})
		)
			.subscribe();

		this.initData().pipe(
			tap((paResults: Group[]) => {
				this.params.data = paResults;
				this.detectChanges();
			})
		)
			.subscribe();
	}

	/** Initialise le jeu de permissions de l'utilisateur et le retourne, retourne un observable vide si la page courante n'est pas une création ou édition. */
	private initHasPermission(): Observable<boolean | never> {
		let loInitPermission$: Observable<boolean | never>;

		if (this.msCurrentUrl.includes(ERouteUrlPart.new) || this.msCurrentUrl.includes(ERouteUrlPart.edit)) {
			const lsPermissionType: TCRUDPermissions = this.msCurrentUrl.includes(ERouteUrlPart.new) ? "create" : "edit";
			loInitPermission$ = of(this.isvcPermissions.evaluatePermission(EPermissionScopes.contacts, lsPermissionType));
		}
		else
			loInitPermission$ = EMPTY;

		return loInitPermission$.pipe(takeUntil(this.fieldDestroyed$));
	}

	/** Initialise les groupes et les retourne, retourne un observable vide s'il n'y a pas de groupe. */
	private initData(): Observable<IGroup[] | never> {
		let loInitData$: Observable<IGroup[] | never>;

		if (!ArrayHelper.hasElements(this.params.groupIds)) {
			if (this.params.readOnly) // Si mode visu et pas d'identifiants de groupes précisés, on récupère ceux en lien avec le modèle courant.
				loInitData$ = this.isvcGroups.getContactGroups(this.model, true);

			else if (ArrayHelper.hasElements(this.params.roles)) // Si mode edit et rôles précisés, on récuère par rôles.
				loInitData$ = this.isvcGroups.getGroupsByRoles(this.params.roles, true);

			else // Si mode edit et pas d'identifiants de groupes précisés, on les récupère tous.
				loInitData$ = this.isvcGroups.getDisplayableGroups(true);
		}
		else
			loInitData$ = EMPTY;

		return loInitData$.pipe(takeUntil(this.fieldDestroyed$));
	}

	public onSelectionChanged(paSelections: IGroupSelection<Group>[]): void {
		if (this.required) // Ne pas oublier de supprimer le champ du modèle lors de la sauvegarde si on utilise des liens.
			this.fieldValue = paSelections.find((poSelection: IGroupSelection<Group>) => poSelection.selected)?.group;

		this.fieldValue = paSelections.filter((poSelection: IGroupSelection<Group>) => poSelection.selected)
			.map((poSelection: IGroupSelection<Group>) => poSelection.group);


		if (this.mbPreselectedGroupsInit)
			this.markAsDirty();
		this.mbPreselectedGroupsInit = true;
	}

	public onClick(): void {
		if (!this.params.readOnly && !this.hasPermission) // Si l'utilisateur n'a pas la permission adéquate, on affiche un toast le signalant.
			this.isvcUiMessageService.showMessage(new ShowMessageParamsToast({ message: "Vous n'avez pas les droits nécessaires pour modifier cet élément." }));
	}

	//#endregion
}