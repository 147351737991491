import { Injectable } from '@angular/core';
import { Observable, defer, of, throwError } from 'rxjs';
import { catchError, defaultIfEmpty, filter, map, mapTo, mergeMap, tap } from 'rxjs/operators';
import { GuidHelper } from '../../../helpers/guidHelper';
import { ConfigData } from '../../../model/config/ConfigData';
import { GalleryFile } from '../../../model/gallery/gallery-file';
import { Picture } from '../../../model/picture/picture';
import { IStoreDataResponse } from '../../../model/store/IStoreDataResponse';
import { IStoreDocument } from '../../../model/store/IStoreDocument';
import { IUiResponse } from '../../../model/uiMessage/IUiResponse';
import { EntityLinkService } from '../../../services/entityLink.service';
import { GalleryService } from '../../../services/gallery.service';
import { ShowMessageParamsPopup } from '../../../services/interfaces/ShowMessageParamsPopup';
import { ShowMessageParamsToast } from '../../../services/interfaces/ShowMessageParamsToast';
import { PatternResolverService } from '../../../services/pattern-resolver.service';
import { Store } from '../../../services/store.service';
import { UiMessageService } from '../../../services/uiMessage.service';
import { DmsFile } from '../../dms/model/DmsFile';
import { IDmsMeta } from '../../dms/model/IDmsMeta';
import { DmsService } from '../../dms/services/dms.service';
import { HooksService } from '../../hooks/services/hooks.service';
import { IEntity } from '../models/ientity';
import { IEntityDescriptor } from '../models/ientity-descriptor';

@Injectable({
	providedIn: 'any'
})
export class EntitiesUpdateService {

	//#region FIELDS

	private static readonly C_LOG_ID = "ENTITIES.UP.S::";
	private static readonly C_DEFAULT_DELETE_MESSAGE = "Voulez-vous vraiment supprimer cette entrée ?";

	//#endregion FIELDS

	//#region METHODS

	constructor(
		private readonly isvcStore: Store,
		private readonly isvcHooks: HooksService,
		private readonly isvcDms: DmsService,
		private readonly isvcGallery: GalleryService,
		private readonly isvcEntityLinks: EntityLinkService,
		private readonly isvcUiMessage: UiMessageService,
		private readonly isvcPatternResolver: PatternResolverService,
	) {

	}

	/** Supprime de la base de données l'entrée.
* @param poModel Entrée à supprimer de la base de données.
* @param psDatabaseId Nom de la base de données sur laquelle effectuer la requête.
*/
	public deleteEntity(poModel: IEntity, poDescriptor: IEntityDescriptor, psDatabaseId?: string): Observable<IStoreDataResponse> {
		return defer(() => this.isvcEntityLinks.ensureIsDeletableEntity(poModel).pipe(catchError(() => of(true)))) // TODO TB supprimer le catchError lors de l'US sur la gestion des entités custom
			.pipe(
				filter((pbDelete: boolean) => pbDelete),
				mergeMap(() => this.showAskingForDeletePopupAsync(this.isvcPatternResolver.resolveContextualPattern(poDescriptor.deleteMessagePattern, { entry: poModel }))),
				filter((pbDelete: boolean) => pbDelete),
				tap(() => this.updateLastChangeIfNeeded(poModel, poDescriptor)),
				mergeMap(() => this.deleteGalleryFiles(poModel)),
				mergeMap(() => this.isvcStore.delete(poModel, psDatabaseId)),
				mergeMap((poResponse: IStoreDataResponse) => {
					if (poResponse.ok)
						this.isvcUiMessage.showToastMessage(new ShowMessageParamsToast({ message: `"${this.isvcPatternResolver.resolveContextualPattern(poDescriptor.namePattern, { entry: poModel })}" supprimé.`, color: "dark" }));

					if (ConfigData.appInfo.useLinks)
						return this.isvcEntityLinks.deleteEntityLinksById(poModel._id).pipe(mapTo(poResponse));
					else
						return of(poResponse);
				}),
				catchError(poError => {
					console.error(`${EntitiesUpdateService.C_LOG_ID}Erreur suppression document ${poModel} de la base ${psDatabaseId}`);

					this.isvcUiMessage.showMessage(
						new ShowMessageParamsPopup({ message: `Erreur lors de la suppression.` })
					);

					return throwError(() => poError);
				})
			);
	}

	public updateLastChangeIfNeeded(poEntity: IEntity, poDescriptor: IEntityDescriptor): void {
		if (poDescriptor.trackLastChangeClient)
			poEntity.lastChange = this.isvcHooks.getLastChange(ConfigData.appInfo.appId);
	}

	protected showAskingForDeletePopupAsync(
		psMessage: string = EntitiesUpdateService.C_DEFAULT_DELETE_MESSAGE
	): Promise<boolean> {
		return this.isvcUiMessage.showAsyncMessage<boolean, any>(new ShowMessageParamsPopup({
			header: psMessage,
			buttons: [
				{ text: "Annuler", handler: () => UiMessageService.getFalsyResponse() },
				{ text: "Oui, supprimer", cssClass: "validate-btn", handler: () => UiMessageService.getTruthyResponse() }],
		}))
			.pipe(
				map((poResponse: IUiResponse<boolean>) => !!poResponse.response)
			)
			.toPromise();
	}

	private deleteGalleryFiles(poModel: IStoreDocument): Observable<boolean> {
		const laGalleryFiles: GalleryFile[] = [];

		Object.keys(poModel).forEach((psKey: string) => {
			const loProp: any = poModel[psKey];
			if (loProp instanceof GalleryFile)
				laGalleryFiles.push(loProp);
			else if (loProp instanceof Array) {
				loProp.forEach((poItem: any) => {
					if (poItem instanceof GalleryFile)
						laGalleryFiles.push(poItem);
				});
			}
		});

		return this.isvcGallery.save$([], laGalleryFiles);
	}

	/** Enregistre le modèle sur la bdd.
 * @param poModel Modèle à enregistrer.
 * @param psDatabaseId Identifiant de la base de données où enregistrer le document.
 */
	public saveModel(poModel: IEntity, poDescriptor: IEntityDescriptor, psDatabaseId?: string): Observable<IStoreDataResponse> {
		return this.saveGalleryFiles(poModel)
			.pipe(
				mergeMap(() => this.savePictures(poModel)),
				tap(() => this.updateLastChangeIfNeeded(poModel, poDescriptor)),
				mergeMap(() =>
					this.isvcStore.put(poModel, psDatabaseId)
						.pipe(catchError(poError => { console.error(`FORM.S:: Erreur lors du put du modèle : `, poError); return throwError(() => poError); }))
				),
				mergeMap((poStoreDataResponse: IStoreDataResponse) => {
					let loResponse$: Observable<IStoreDataResponse> = of(poStoreDataResponse);
					if (ConfigData.appInfo.useLinks) {
						try {
							loResponse$ = this.isvcEntityLinks.saveEntityLinks(poModel).pipe(mapTo(poStoreDataResponse));
						}
						catch (poError) {
							console.error(`${EntitiesUpdateService.C_LOG_ID}Error while trying to save links for ${poModel._id}. Continuing anyway.`, poError);
						}
					}
					return loResponse$;
				})
			);
	}

	private savePictures(poModel: IStoreDocument): Observable<boolean> {
		return defer(() => {
			const laPictures: Picture[] = [];

			Object.keys(poModel).forEach((psKey: string) => {
				const loProp: any = poModel[psKey];
				if (loProp instanceof Picture)
					laPictures.push(loProp);
			});

			return laPictures;
		}).pipe(
			mergeMap((poPicture: Picture) => {
				let loDmsFile: DmsFile | undefined;
				let loDmsMeta: IDmsMeta | undefined;
				if (poPicture.file && poPicture.alt) {
					loDmsFile = new DmsFile(poPicture.file, poPicture.alt);
					loDmsMeta = loDmsFile.createDmsMeta(poPicture.guid ?? GuidHelper.newGuid());
				}
				return loDmsFile && loDmsMeta ? this.isvcDms.save(loDmsFile, loDmsMeta).pipe(map((poDmsMeta: IDmsMeta) => !!poDmsMeta)) : of(true);
			}),
			defaultIfEmpty(true)
		);
	}

	private saveGalleryFiles(poModel: IStoreDocument): Observable<boolean> {
		const laGalleryFiles: GalleryFile[] = [];

		Object.keys(poModel).forEach((psKey: string) => {
			const loProp: any = poModel[psKey];
			if (loProp instanceof GalleryFile)
				laGalleryFiles.push(loProp);
			else if (loProp instanceof Array) {
				loProp.forEach((poItem: any) => {
					if (poItem instanceof GalleryFile)
						laGalleryFiles.push(poItem);
				});
			}
		});

		return this.isvcGallery.save$(laGalleryFiles);
	}


	//#endregion METHODS

}
