import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Observable, defer, from, of } from 'rxjs';
import { mapTo, mergeMap, startWith, tap } from 'rxjs/operators';
import { IdHelper } from '../../../../../../helpers/idHelper';
import { ObjectHelper } from '../../../../../../helpers/objectHelper';
import { EPrefix } from '../../../../../../model/EPrefix';
import { DocExplorerConfig } from '../../../../../doc-explorer/models/doc-explorer-config';
import { FolderConfig } from '../../../../../doc-explorer/models/folder-config';
import { IDocumentForm } from '../../../../../doc-explorer/models/idocument-form';
import { DocExplorerDocumentsService } from '../../../../../doc-explorer/services/doc-explorer-documents.service';
import { EntitiesService } from '../../../../../entities/services/entities.service';
import { secure } from '../../../../../utils/rxjs/operators/secure';
import { FieldBase } from '../../../../models/FieldBase';
import { FormsService } from '../../../../services/forms.service';
import { IDocumentMetaFieldParams } from '../../models/idocument-meta-field-params';
import { IMetaField } from '../../models/imeta-field';

@Component({
	selector: 'calao-document-meta-field',
	templateUrl: './document-meta-field.component.html',
	styleUrls: ['./document-meta-field.component.scss'],
})
export class DocumentMetaFieldComponent extends FieldBase<{}> implements OnInit {

	//#region PROPERTIES

	private readonly C_DOCUMENT_PATHS = "paths";

	//#endregion PROPERTIES

	//#region METHODS

	public params: IDocumentMetaFieldParams;

	constructor(
		private readonly isvcEntities: EntitiesService,
		private readonly isvcDocExplorerDocuments: DocExplorerDocumentsService,
		psvcForms: FormsService,
		poChangeDetectorRef: ChangeDetectorRef
	) {
		super(psvcForms, poChangeDetectorRef);
	}

	public override ngOnInit(): void {
		super.ngOnInit();

		this.params = this.to.data ?? { fields: {} };
		this.fieldValue = this.fieldValue ?? {};

		this.init();
	}

	private init(): void {
		const laValueChangesListeners: Observable<void>[] = [];

		Object.entries(this.params.fields).forEach(([psKey, poValue]: [string, IMetaField]) => {
			if (poValue.modelKey) {
				const loFormControl: AbstractControl<any, any> | undefined = this.form.controls[poValue.modelKey];
				const loUpdateFieldValue$ = defer(() => loFormControl ?
					loFormControl.valueChanges.pipe(startWith(this.model[poValue.modelKey])) :
					of(this.model[poValue.modelKey])
				).pipe(
					tap((poFieldValue: any) => {
						if (ObjectHelper.isDefined(poFieldValue)) {
							this.fieldValue[psKey] = poFieldValue;
							this.formControl.patchValue(this.fieldValue);
						}

						if (psKey == this.C_DOCUMENT_PATHS && !poFieldValue && this.params.parent) {
							this.addPathsFromDocsExplorer();
						}
					}),
					mapTo(undefined)
				);
				laValueChangesListeners.push(loUpdateFieldValue$);
			}
			else if (ObjectHelper.isDefined(poValue.value) && (!ObjectHelper.isDefined(this.fieldValue[psKey]) || !poValue.oneTimeFilled)) {
				this.fieldValue[psKey] = poValue.value;
				this.formControl.patchValue(this.fieldValue);
			}
		});

		defer(() => from(laValueChangesListeners)).pipe(
			mergeMap((poValueChanges$: Observable<void>) => poValueChanges$),
			secure(this)
		).subscribe();
	}

	private addPathsFromDocsExplorer(): void {
		const lsModelType: string = IdHelper.getGuidFromId(this.isvcEntities.getEntityDescriptor(this.model)._id, EPrefix.entityDesc).split("_")[0];
		const paPaths: string[] = this.isvcEntities.getEntityDocumentPaths(this.params.parent);

		this.isvcDocExplorerDocuments.getConfig$().pipe(
			tap((poConfig: DocExplorerConfig) => {
				const laFolderConfig: FolderConfig[] = poConfig.paths.filter(
					(poPathConfig: FolderConfig) => paPaths.some((psDocumentPath: string) => this.isvcDocExplorerDocuments.matchPath(psDocumentPath.replace("\\", "/").split("_")[0], poPathConfig.path))
				);

				for (let poFolderConfig of laFolderConfig) {
					if (poFolderConfig.documentTypes?.forms?.some((poForms: IDocumentForm) => poForms.descriptor === lsModelType)) {
						this.fieldValue[this.C_DOCUMENT_PATHS] = [poFolderConfig.path.replace(".*", this.params.parent._id.split("_")[1])];
						break;
					}
				}
			})
		).subscribe();
	}

	//#endregion
}
